<template>
  <Overlay>
    <h1 slot="header">Registrierung</h1>

    <div slot="body">
      <div class="description">
        Registrieren Sie sich für die redaktionelle Nutzung der FMH-Services.
      </div>
      <div class="loginform">
        <form @submit="processForm">
          <span class="error__main" v-if="errors.general">{{ errors.general }}</span>
          <label>
            <span class="label">E-Mail*</span>
            <input type="email" name="email" v-model="form.email">
            <span class="error" v-if="errors.email">{{ errors.email }}</span>
          </label>

          <br>
          <label>
            <span class="label">Passwort*</span>
            <input type="password" name="password" v-model="form.password">
            <span class="error" v-if="errors.password">{{ errors.password }}</span>
          </label>
          <br>
          <label>
            <span class="label">Passwort Wiederholung*</span>
            <input type="password" name="password2" v-model="form.password2">
            <span class="error" v-if="errors.password2">{{ errors.password2 }}</span>
          </label>

          {{message}}
          <button class="button--primary">Registrieren</button>
        </form>

        <div class="hint">
          * Pflichtfelder
        </div>
      </div>
    </div>
  </Overlay>
</template>

<script>
  import Overlay from "../../components/Overlay";
  import axios from 'axios';
  import RequestError from "../../global/errors/RequestError";

  let loginclient = axios.create();

  export default {
    name: "Register",
    data: () => ({
      errors: {email:'', password:'', password2:'', general:'', changed: 0},
      form: {
        email: '',
        password: '',
        password2: '',
        roles: ['editor'],
        returnUrl: process.env.DASHBOARDURL
      },
      message: ''
    }),
    components: {
      Overlay
    },
    methods:{
      processForm: function(e){
        e.preventDefault();
        if(!this.form.email) {
          this.errors.general = 'Bitte überprüfen Sie Ihre Angaben';
          this.errors.email = 'Bitte E-Mail angeben';
          this.errors.changed = true;
        }
        if(!this.form.password) {
          this.errors.general = 'Bitte überprüfen Sie Ihre Angaben';
          this.errors.password = 'Bitte Passwort angeben';
          this.errors.changed = true;
        }
        if(this.form.password !== this.form.password2) {
          this.errors.general = 'Bitte überprüfen Sie Ihre Angaben';
          this.errors.password2 = 'Passwörter stimmen nicht überein';
          this.errors.changed = true;
        }

        if(!this.errors.changed) {
          this.errors = [];
          loginclient.post('https://new-users.fmh.de/api/public/users/register', this.form)
          .then((response) => {
            if (response.status !== 200) {
              throw new RequestError('Something went wrong!');
            }
            if (response.data.error) {
              let self = this;
              this.message = response.data.error.message;
              Object.keys(response.data.error.errors).forEach((k) => {
                if(self.errors[k] !== 'undefined'){
                  self.errors[k] = response.data.error.errors[k].message;
                }
                self.errors.changed = true;
              });
            } else {
              this.message = "Registrierung erfolgreich. Bitte die Email bestätigen."
            }
            //this.$router.push('dashboard?token=' + response.data.token);
          })
          .catch((error) => {
            throw new RequestError(error.message);
          });
        }
        this.errors.changed = '';
      }
    }
  }
</script>

<style scoped>
  .description {
    padding-top: 20px;
  }

  .loginform {
    padding-top: 20px;
  }

  input {
    width: 100%;
    line-height: 30px;
    border: 2px solid lightgrey;
  }

  label {
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    display: inline-block;
    text-align: left;
    width: 100%;
    margin-bottom: 20px;
  }

  .label {
    margin-left: 10px;
  }

  .error, .error__main {
    color: darkred;
  }
  .error{
    font-weight: 400;
    font-size: 14.5px;
  }
  .error__main {
    font-weight: 600;
    font-size: 14.5px;
  }
  .button--primary {
    width: 100%;
  }

  .hint {
    padding-top: 10px;
    color: #767676;
    text-align: left;
    font-size: 12px;
  }
</style>
