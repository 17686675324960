<template>
  <div class="button-bar" v-if="buttons">
     <router-link :to="get_link(button.route)"
      v-for="(button, index) in buttons"
      :key="index"
     >
        <button class="menu-button" :class="button.icon">{{ button.text }}</button>
     </router-link>
  </div>
</template>

<script>
  import buttonbars from "./ButtonbarConfig";
  export default {
    name: "Buttonbar",
    props : {
      name: String,
      token: String,
      service: String
    },
    computed: {
      buttons: function () {
        return buttonbars[this.name];
      }
    },
    methods: {
      get_link: function (route) {
        route = this.replace_service(route);
        return this.token ? route + '?token=' + this.token : route;
      },
      replace_service: function (pattern) {
        if(pattern.indexOf('<service>') >= 0) {
          return pattern.replace('<service>', this.service);
        }
        return pattern;
      }
    },
    mounted: function mounted() {
      this.buttons.forEach(button => {
        let page_id = this.replace_service(button.page_id);
        this.$store.dispatch('loadPageIfMissing', { page_id: page_id })
      })
    }
  }
</script>

<style lang="scss">

</style>
