<template>
  <div>
    <transition name="slide-and-fade">
      <div class="segment__content no-button">
        <div class="content__center iib-dichte-text" v-if="hasEntries">
          Der Markt in {{ location }} ist
        </div>
        <div class="content__center iib-dichte-light" v-if="hasEntries">
          <span class="item__lights" :class="densityData.color">{{ densityData.label }}</span>
        </div>
        <div class="content__center iib-dichte-text" v-if="hasEntries">
          Auf <strong>eine</strong> Nachfrage existieren<br />
          <strong>{{ densityData.density }} Angebote</strong>
        </div>

        <div class="segment__noentries" v-else>Keine Daten</div>

      </div>
    </transition>
  </div>
</template>

<script>
  import IibBaseTile from "./IibBaseTile";

  export default {
    name: "IibDichteTile",
    extends: IibBaseTile,
    props: {
      data: Object
    },
    computed: {
      hasEntries: function() {
        return this.data && this.data.entries && this.data.entries.length > 0
      },
      entries: function () {
        return this.data.entries;
      },
      location: function() {
        return this.priceEntry.name
        //return this.localizator.city
      },
      priceEntry: function () {
        return this.entries[0]
      },
      cheapRow: function () {
        return {
          "category": "günstig",
          "price": this.priceEntry.angebotspreise_monat_guenstig,
          "tendence":  this.priceEntry.angebotspreise_monat_guenstig_tendenz
        }
      },
      fairRow: function () {
        return {
          "category": "fair",
          "price": this.priceEntry.angebotspreise_monat_fair,
          "tendence":  this.priceEntry.angebotspreise_monat_fair_tendenz
        }
      },
      expensiveRow: function () {
        return {
          "category": "teuer",
          "price": this.priceEntry.angebotspreise_monat_teuer,
          "tendence":  this.priceEntry.angebotspreise_monat_teuer_tendenz
        }
      },
      priceTable: function () {
        return [
          this.cheapRow,
          this.fairRow,
          this.expensiveRow
        ]
      },
      densityData: function () {
        let density = this.priceEntry.angebote_haushalte/this.priceEntry.bedarf_nach_kategorie;
        let label = density > 1.1 ? "üppig" : density < 0.9 ? "knapp" : "ausgewogen";
        let color = density > 1.1 ? "greenlight" : density < 0.9 ? "redlight" : "yellowlight";
        return {
          "density": density.toFixed(2),
          "color": color,
          "label": label
        }
      }
    },
    methods: {
      arrow: function (value) {
        return this.$filters.arrow(value)
      },
      tendence: function (value) {
        return this.$filters.tendence(value)
      }
    }
  }

</script>

<style lang="scss">


</style>
