import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'enumByType'
    ]),
    enumDatatypes: function () {
      return this.enumByType('config').DATATYPES
    },
    enumElementtypes: function () {
      return this.enumByType('config').ELEMENTTYPES
    },
    enumPeriods: function () {
      return this.enumByType('config').PERIODS
    },
    enumServices: function () {
      return this.enumByType('config').SERVICES
    },
    enumTendencies: function () {
      return this.enumByType('config').TENDENCIES
    },
  },
  methods: {
    ...mapActions([
      'loadEnum',
    ]),
  }
}
