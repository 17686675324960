<template>
  <div class="wrapper" v-if="hasEntries">
    <div class="table-filter-container">
      <div class="table__filters" v-if="hasTablefilter">
        <Tablefilter
          :field="filterField"
          :filter-options="filterOptions"
          :label="filterLabel"
          :init-options="filterKeys"
          @setFilter="onSetFilter"
        >
        </Tablefilter>
      </div>
      <vue-good-table
        class="common__table"
        :class="[showResponsiveCards ? 'hide-below-bp-m' : '']"
        :columns="columns"
        :rows="filtered"
        :row-style-class="'tr-row'"
        :sort-options="sortOptions"
        :group-options="groupOptions"
        styleClass="common__table"
      >
        <template slot="table-column" slot-scope="props">
          <span v-html="props.column.label"></span>
        </template>

        <template slot="table-row" slot-scope="props">
          <bank-entry v-if="props.column.grouptype === 'bank'"
                      :entry="props.row"
                      :replacewith="props.column.replacewith"
                      :onlyBankname="onlyBankname"
          ></bank-entry>
          <zins-entry v-if="props.column.grouptype === 'interest'"
                      :entry="props.row"
                      :field="props.column"
                      :tendency-colors="tendencyColors"
          ></zins-entry>
          <extended-entry v-if="props.column.grouptype === 'extended'"
                      :entry="props.row"
                      :field="props.column"
          ></extended-entry>

        </template>
        <template slot="table-actions-bottom" v-if="morebutton">
           <div class="table__footer">
              <div class="button-bar">
                <more-button :config="morebutton"></more-button>
              </div>
          </div>
        </template>
        <template slot="emptystate">
          <div  class="table__filters--nodata">
            Keine Daten bei dieser Filter-Auswahl
          </div>
        </template>
      </vue-good-table>
      <div class="common__cards hide-over-bp-m" v-if="showResponsiveCards">
        <fragment v-if="Object.keys(groupedEntries).length > 0">
          <fragment v-for="(entry, index) in groupedEntries" :key="index">
            <div class="cards__title">
                <h5 class="title__grouptitle">{{ entry.label }}</h5>
            </div>
            <!-- TODO: make a subcomponent out of it (e.g. TableCardElement= -->
            <div class="card__single" v-for="(item, index) in entry.children" :key="index">
              <div class="segment__bank" v-if="bankFieldgroups">
                <bank-entry
                  :entry="item"
                  :replacewith="bankFieldgroups.fields[0].replacewith"
                  :onlyBankname="onlyBankname"
                ></bank-entry>
              </div>
              <div class="segment__highlight segment__interest" v-if="interestFieldgroups">
                <zins-card-entry
                  :entry="item"
                  :fieldgroup="interestFieldgroups"
                  :tendency-colors="tendencyColors"
                ></zins-card-entry>
              </div>
              <div class="segment__content" v-if="extendedFieldgroups">
                <extended-card-entry
                  :entry="item"
                  :fieldgroups="extendedFieldgroups"
                ></extended-card-entry>
              </div>
            </div>
          </fragment>
        </fragment>
        <fragment v-else>
          <!-- TODO: make a subcomponent out of it (e.g. TableCardElement= -->
          <div class="card__single" v-for="(item, index) in filtered" :key="index">
            <div class="segment__bank" v-if="bankFieldgroups">
              <bank-entry
                :entry="item"
                :replacewith="bankFieldgroups.fields[0].replacewith"
                :onlyBankname="onlyBankname"
              ></bank-entry>
            </div>
            <div class="segment__highlight segment__interest" v-if="interestFieldgroups">
              <zins-card-entry
                :entry="item"
                :fieldgroup="interestFieldgroups"
                :tendency-colors="tendencyColors"
              ></zins-card-entry>
            </div>
            <div class="segment__content" v-if="extendedFieldgroups">
              <extended-card-entry
                :entry="item"
                :fieldgroups="extendedFieldgroups"
              ></extended-card-entry>
            </div>
          </div>
        </fragment>
        <div class="cards__footer" v-if="morebutton">
          <div class="button-bar">
            <more-button :config="morebutton"></more-button>
          </div>
        </div>
      </div>
      <div class="element__timestamp">Stand: {{ currentElement.timestamp|date }}</div>
    </div>

  </div>
  <div class="section__noentries" v-else>Keine Daten in diesem Abschnitt</div>

</template>
<script>
  import { VueGoodTable } from 'vue-good-table';
  import { Fragment } from "vue-fragment";
  import Vue from 'vue'

  import BaseElement from "./BaseElement";
  import BankEntry from "./entries/BankEntry";
  import ZinsEntry from "./entries/ZinsEntry";
  import ExtendedEntry from "./entries/ExtendedEntry";
  import ZinsCardEntry from "./entries/ZinsCardEntry";
  import ExtendedCardEntry from "./entries/ExtendedCardEntry";
  import Tablefilter from "./shared/Tablefilter";
  import MoreButton from "./shared/MoreButton";

  export default {
    name: "TableElement",
    extends: BaseElement,
    props: {
      tendencyColors: String,
      accessGroup: String,
      onlyBankname: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        filters: {}
      }
    },
    components: {
      VueGoodTable,
      Fragment,
      BankEntry,
      ZinsEntry,
      ExtendedEntry,
      ZinsCardEntry,
      ExtendedCardEntry,
      MoreButton,
      Tablefilter
    },
    computed: {
      columns: function () {
        let columns =  [];
        for(let fieldgroup of this.fieldGroups) {
          for(let field of fieldgroup.fields) {
            if(!field.restrictto || (field.restrictto && field.restrictto === this.accessGroup)) {
              field['grouptype'] = fieldgroup.type;
              if(fieldgroup.hasOwnProperty('replacewith')){
                 field['replacewith'] = fieldgroup.replacewith
              }

              if(field['type'] === 'date') {
                field['dateInputFormat'] = 'yyyy-MM-dd';
                field['dateOutputFormat'] = 'dd.MM.yy'
              }

              field['thClass'] = 'th-cell';
              field['tdClass'] = 'td-cell';

              columns.push(field);
            }
          }
        }
        return columns
      },
      hasTablefilter: function () {
        return 'tablefilter' in this.currentElement.extras;
      },
      getTablefilter: function() {
        return this.currentElement.extras.tablefilter;
      },
      filterOptions: function () {
        let options = [];
        if(this.hasTablefilter) {
          let values = this.entries.map(entry => entry[this.getTablefilter.field]);
          let distinct = [...new Set(values)];
          distinct.sort(function(a, b){return a-b});
          for(let distinct_value of distinct) {
            let formatted_value = Vue.filter(this.getTablefilter.filter)(distinct_value);
            options.push({
              key: distinct_value,
              label: formatted_value
            })
          }
        }
        return options;
      },
      filterKeys: function() {
        return this.filterOptions.map(filter => filter.key);
      },
      filterLabel: function () {
        return this.hasTablefilter ? this.getTablefilter.label : '';
      },
      filterField: function () {
        return this.hasTablefilter ? this.getTablefilter.field : '';
      },
      filtered: function () {
        let filtered_entries = this.entries;
        if(this.filters) {
          for(let filter in this.filters) {
            filtered_entries = filtered_entries.filter(entry => this.filters[filter].indexOf(entry[filter]) >= 0 );
          }
        }
        return filtered_entries.map(obj => {
          let o = {};
          Object.keys(obj).forEach(k => {
            if(typeof obj[k] === 'number') {
              Object.assign(o, {[k]: String(obj[k])})
            } else {
              Object.assign(o, {[k]: obj[k]})
            }
          })
          return o;
        });
      },

      sortOptions: function () {
        return 'sort-options' in this.currentElement.extras ? this.currentElement.extras['sort-options'] : {};
      },
      groupOptions: function () {
        return this.currentElement.extras.display && 'groupby' in this.currentElement.extras.display ? { enabled: true } : { enabled: false };
      },
      fieldGroups: function () {
        return this.currentElement.extras.fieldgroups;
      },
      bankFieldgroups:function () {
        return this.fieldGroups.find(fg => fg.type === 'bank');
      },
      interestFieldgroups:function () {
        return this.fieldGroups.find(fg => fg.type === 'interest');
      },
      extendedFieldgroups:function () {
        return this.fieldGroups.filter(fg => fg.type === 'extended');
      },
      morebutton: function () {
        return 'morebutton' in this.currentElement.extras ? this.currentElement.extras.morebutton : false;
      },
      groupedEntries: function () {
        if(this.hasEntries && 'children' in this.entries[0]) {
          return this.entries;
        }
        return {};
      },
      showResponsiveCards: function () {
        return 'responsive-cards' in this.currentElement.extras ? this.currentElement.extras['responsive-cards'] : true;
      }
    },
    methods: {
      onSetFilter(field, options) {
        this.$set(this.filters, field, options);
      }
    },
  }
</script>
