<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <span class="modal-default-button" @click="$emit('close')" v-if="canClose">
            Schließen <span class="symbol">&times;</span>
          </span>
          <div class="modal-header">
            <slot name="header">
              default header
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              default body
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">

            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: "Modal",
    props: {
      canClose: { type: Boolean, default: true }
    },
    data: () => ({
      showModal: false
    })
  }
</script>

<style scoped>
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 60%;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #1792D0;
    color: white;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-size: 16px;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
    font-size: 18px;
  }

  h4 {
    font-size: 16px;
  }

  .modal-body {
    margin: 20px 0;
  }

  .modal-default-button {
    font-size: 12px;
    float: right;
    cursor: pointer;
  }

  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .modal-header {
    text-align: center;
  }

  .symbol {
    font-size: 30px;
    vertical-align: bottom;
  }
</style>
