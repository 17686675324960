import { createEditorsAPI } from '@/global/apis';
import ApiError from "@/global/errors/ApiError";

const editorsAPI = createEditorsAPI();

export default {

  /**
   * Loads the dashboard and adds it to the store
   *
   * @returns void
   */
  loadDashboard: (context) => {

    context.commit('startLoading');

    editorsAPI.getDashboard()
      .then((data) => {
        context.commit('setDashboard', data);
        if(data.dashboard.linked_pages) {
          for( let page of data.dashboard.linked_pages ) {
            context.dispatch('loadPage', { page_id: page });
          }
        }
      })
      .catch((error) => {
        /* eslint no-console: off */
        console.log(error);
        context.commit('addNotification', {
          type: 'error',
          title: 'Error in editorsAPI.getDashboard()',
          text: error.stack
        });
        throw new ApiError(error)
      })
      .then(() => {
        context.commit('finishLoading');
      });
  },

  /**
   * Load the element and add it to the store
   * @param commit
   * @param id
   * @returns void
   */
  loadElement:({ commit }, id) => {
    editorsAPI.getElement(id)
      .then((data) => {
        commit('addElement', data);
      })
      .catch((error) => {
        commit('addNotification', {
          type: 'error',
          title: 'Error in editorsAPI.getElement()',
          text: error.stack
        });
      })
  },

  /**
   * Load the page_config for given page_id and add it to the store
   * @param commit
   * @param page_id
   * @returns void
   */
  loadPage: ({commit}, {page_id}) => {
    editorsAPI.getPage(page_id)
      .then((data) => {
        commit('addPage', { page_id: page_id, page: data });
      })
      .catch((error) => {
        /* eslint no-console: off */
        console.log('loadPage with page_id=' + page_id + '\n' + error);
        commit('addNotification', {
          type: 'error',
          title: 'Error in editorsAPI.getPage()',
          text: error.stack
        });
      })
  },

    /**
   * Check if given page_id is already loaded, otherwise load it to store
   * @param context
   * @param page_id
   * @returns void
   */
  loadPageIfMissing: (context, { page_id }) => {
    if(!context.getters.pageExists(page_id)) {
      context.dispatch('loadPage', { page_id: page_id })
    }
  },

  /**
   * Load the element-data by url and add it to the store
   * @param commit
   * @param url
   * @returns void
   */
  loadData:({ commit }, url) => {
    editorsAPI.getDatUrl(url)
      .then((data) => {
        commit('addElement', data);
      })
      .catch((error) => {
        commit('addNotification', {
          type: 'error',
          title: 'Error in editorsAPI.getDataUrl() with url: ' + url,
          text: error + "\n" + error.stack
        });
      })
  },

  loadGraph:({commit}, url) => {
    editorsAPI.getDatUrl(url)
        .then((data) => {
          if(data.data instanceof Array){
            data.data = data.data[0];
          }
          commit('addGraphs', data);
        })
        .catch((error) => {
          commit('addNotification', {
            type: 'error',
            title: 'Error in editorsAPI.loadGraphs() with url: ' + url,
            text: error + "\n" + error.stack
          });
        })
  },

  loadDataIIB:({ commit }, obj) => {
    editorsAPI.getDatUrlLoc(obj.url, obj.localizator)
      .then((data) => {
        commit('addElement', data);
      })
      .catch((error) => {
        commit('addNotification', {
          type: 'error',
          title: 'Error in editorsAPI.getDataUrl() with url: ' + obj.url,
          text: error + "\n" + error.stack
        });
      })
  },

  updateDataIIB:({ commit }, obj) => {
    editorsAPI.getDatUrlLoc(obj.url, obj.localizator)
      .then((data) => {
        commit('updateElement', data);
      })
      .catch((error) => {
        commit('addNotification', {
          type: 'error',
          title: 'Error in editorsAPI.getDataUrl() with url: ' + obj.url,
          text: error + "\n" + error.stack
        });
      })
  },

  /**
   * Load the cms-topic (json-representation corresponding to template) from Kastners cms-service
   * @param commit
   * @param id
   * @returns void
   */
  loadCmsTopic:({ commit }, id) => {
    editorsAPI.getTopic(id)
      .then((data) => {
        commit('addCmsTopic', data);
      })
      .catch((error) => {
        /* eslint no-console: off */
        console.log(error);
        commit('addNotification', {
          type: 'error',
          title: 'Error in editorsAPI.getTopic() with id: ' + id,
          text: error + "\n" + error.stack
        });
      })
  },

    /**
   * Load the enum by type from enums-api
   * @param commit
   * @param type
   * @returns void
   */
  loadEnum:({ commit }, type) => {
    editorsAPI.getEnum(type)
      .then((data) => {
        commit('addEnum', { type: type, enums: data });
      })
      .catch((error) => {
        /* eslint no-console: off */
        console.log(error);
        commit('addNotification', {
          type: 'error',
          title: 'Error in editorsAPI.getEnum() with type: ' + type,
          text: error + "\n" + error.stack
        });
      })
  },

  loadLocalizator:({commit}, name) => {
    editorsAPI.getLocation(name)
      .then((data) => {
        commit('addLocalizator', data);
      })
      .catch((error) => {
        console.log(error);
        commit('addNotification', {
          type: 'error',
          title: 'Error in editorsApi.getLocation() with query: ' + name,
          text: error + "\n" + error.stack
        });
      })
  },

  updateLocalizator:({commit}, data) => {
    commit('addLocalizator', data);
  },

  triggerModal:({commit}) => {
    commit('triggerInfolayer');
  },

  triggerRegistration:({commit}, data) => {
    commit('triggerRegistrationLayer', data);
  },

  closeAllLayers:({commit}) => {
    window.console.log('closing all layers');
    commit('triggerLogin', {'type': 'login', 'value': false});
    commit('triggerInfolayerMode', false);
    commit('triggerRegistrationLayer', false);
  },

  openLayer:({commit}, type) => {
    commit('triggerLogin', {'type': type, 'value': true});
  },

  closeLayer:({commit}, type) => {
    commit('triggerLogin', {'type':type, 'value':false});
  },

  showLogin:({commit}, data) => {
    commit('showLogin', data);
  }
};
