<template>
  <fragment>
    <div class="item__line" v-if="entry.camount"><b>{{ entry.label }}: </b>
      <TendenceIcons :tendence="entry.camount" :tendency-colors="tendencyColors"></TendenceIcons>
      <br> {{ entry.ccount }}{{ ctendency }}. Tendenz: {{ entry.camount }}
    </div>
  </fragment>
</template>

<script>
    import TendenceIcons from '../TendenceIcon';
    import {Fragment} from 'vue-fragment'

    export default {
        name: "TrendEntry",
        props: {
            entry: Object,
            tendencyColors: String
        },
        components: {
            Fragment,
            TendenceIcons
        },
        computed: {
            ctendency: function () {
                return `, ${this.entry.ctendency}`;
            }
        }
    }
</script>

<style>
  .item__line {
    padding-bottom: 1.5em;
  }
</style>
