<template>
  <transition name="fade" mode="out-in" appear>
    <div class="overlay-mask">
      <div class="overlay-wrapper">
        <div class="overlay-container">
          <span class="overlay-default-button" @click="$parent.$emit('close')">
            Schließen <span class="symbol">&times;</span>
          </span>
          <div class="overlay__header">
            <slot name="header">
              <h1>Title</h1>
            </slot>
          </div>
          <div class="overlay-content">
            <div class="overlay__body">
              <slot name="body"></slot>
            </div>
            <div class="overlay__footer">
              <slot name="footer"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: "Overlay"
  }
</script>

<style scoped>
  .overlay-mask {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9998;
    display: table;
    transition: opacity 0.3s ease;
    position: fixed;
  }

  .overlay-wrapper {
    width: 100%;
    margin: 0px auto;
  }

  .overlay-container {
    margin: 0px auto;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 10000;
    background: white none repeat scroll 0% 0%;
    transition: height 1s ease;
    height: calc(100%/(12/9));
    padding-bottom: 60px;
  }

  @media screen and (max-width: 1074px) {
    .overlay-container {
      top: 90px;
    }
  }

  .overlay-content {
    max-width: 350px;
    margin: 0px auto;
  }

  .symbol {
    font-size: 30px;
    vertical-align: bottom;
  }

  .overlay-default-button {
    font-size: 12px;
    float: right;
    cursor: pointer;
    color: white;
    padding-top: 15px;
    padding-right: 15px;
  }

  .overlay__body {
    text-align: center;
  }

  .overlay__header {
    height: 80px;
    background-color: #1792D0;
    color: white;
    text-align: center;
    padding-top: 20px;
    line-height: 24px;
  }

  .overlay__header h1 {
    text-align: center;
    padding: 20px 10px;
    font-size: 18px;
  }
</style>
