<template>
  <fragment>
    <transition name="slide-and-fade">
      <div class="segment__content" v-if="hasEntries">
        <table class="content__table">
          <tr>
            <td colspan="4">Banken (bundesweit: Beleihung 60%)</td>
          </tr>
          <tr v-for="(item, index) in entries.slice(0, 3)" :key="`0 + ${index}`">
            <td class="item__rang">{{ item|rang(data.display) }}</td>
            <td class="item__bank">{{ item.Bankname }}</td>
            <td class="item__interest">{{ item[satzField]|percentage }}</td>
          </tr>
          <tr>
            <td colspan="4">Vermittler (Beleihung: 60%)</td>
          </tr>
          <tr v-for="(item, index) in entries.slice(3, 6)" :key="`1 + ${index}`">
            <td class="item__rang">{{ item|rang(data.display) }}</td>
            <td class="item__bank">{{ item.Bankname }}</td>
            <td class="item__interest">{{ item[satzField]|percentage }}</td>
          </tr>
        </table>
      </div>
      <div class="segment__noentries" v-else>Keine Top-Angebote</div>
    </transition>
    <div class="segment__bottom" v-if="hasButton && hasEntries">
      <router-link :to="routerLink">
        <button class="more-button">{{ buttonText }}</button>
      </router-link>
    </div>
  </fragment>
</template>

<script>
    import {Fragment} from 'vue-fragment'
    import buttons from '@/mixins/buttons'

    export default {
        name: "FmhTopangeboteTileSplit",
        mixins: [buttons],
        props: {
            data: Object
        },
        components: {
            Fragment
        },
        computed: {
            hasEntries: function () {
                return this.data && this.data.entries && this.data.entries.length > 0
            },
            entries: function () {
                return this.data.entries;
            },
            satzField: function () {
                if (this.data.display.zinsen_fields) {
                    return this.data.display.zinsen_fields.satz;
                }
                return 'Zinssatz';
            },
        },
    }
</script>

