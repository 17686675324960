<template>
  <div class="common__cards" v-if="notEmpty(cards)">
    <div v-for="(card, index) in cards" :key="index" class="card__single stats-card">
      <div class="segment__banner" v-if="notEmpty(card)">
        {{ card.label }}
      </div>
      <div class="segment__content separator" v-if="notEmpty(card)">
        <table class="content__table">
          <tr class="table__item">
            <td class="item__label"><strong>Durchschnitt: *</strong></td>
            <td class="item__value"><strong>{{ card.avg_diff|percentage }}</strong></td>
          </tr>
          <tr class="table__item">
            <td class="item__label"><strong>Änderungen:</strong></td>
            <td class="item__value"><strong>{{ card.count }}</strong></td>
          </tr>
          <tr class="table__item" v-if="(card.amount_available)">
            <td colspan="2" class="item__label"><strong>max {{ card.amount_available }} mögl. Änderungen pro Tag.</strong></td>
          </tr>
        </table>
      </div>
      <div class="segment__content separator" v-if="notEmpty(card)">
        <table class="content__table">
          <tr class="table__item">
            <td class="item__label item-centered" v-if="card.count_diff_pos > 0"><span :class="[tendencyColors]" class="tendence-up"></span></td>
            <td class="item__value item-centered" v-if="card.count_diff_neg > 0"><span :class="[tendencyColors]" class="tendence-down"></span></td>
          </tr>
          <tr class="table__item">
            <td class="item-centered" v-if="card.count_diff_pos > 0">
              &#8960;: {{ card.avg_diff_pos|percentage }}<br />
              &#916;: {{ card.count_diff_pos }}
            </td>
            <td class="item-centered" v-if="card.count_diff_neg > 0">
              &#8960;: {{ card.avg_diff_neg|percentage }}<br />
              &#916;: {{ card.count_diff_neg }}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="element__info">
    * Durchschnitt = Mittelwert der geänderten neuen Zinsen.
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import BaseElement from "./BaseElement";

  export default {
    name: "StatsCardsElement",
    extends: BaseElement,
    props: {
      element: Object,
      tendencyColors: String
    },
    components: {
    },
    computed: {
      cards: function () {
        return this.currentElement.cards ? this.currentElement.cards : [];
      },
    },
    methods: {
      notEmpty(object) {
        return Object.keys(object).length > 0
      },
      filterGroupKey: function (groupkey) {
        let filtername = 'groupkeyFilter' in this.element.extras.cards_config ? this.element.extras.cards_config.groupkeyFilter : 'duration';
        return Vue.filter(filtername)(groupkey)
      }
    },
  }
</script>

