<script>
import WochenChart from "@/elements/WochenChart";
import Vue from 'vue';

export default {
  name: "PrognoseChart",
  extends: WochenChart,
  methods: {
    loadData: function () {
      let marker = {
        symbolSize: 1,
        symbolOffset: [0, '50%'],
        label: {
          //formatter: '{c|{c?.toFixed(2)}%}',
          formatter: (params) =>{
            return `${params.data.value.toFixed(2)}%`
          },
          backgroundColor: 'rgba(242,242,242,0.5)',
          padding: [5, 5],
          lineHeight: 26,
          position: 'bottom',
          distance: 10,
          rich: {
            c: {
              color: '#333',
            }
          }
        },
        data: [
          { type: 'max', name: '' }
        ]
      };


      this.option.title.text = this.getData().name;
      this.option.legend.data = this.getData().dataSets.map((item) => {
        return {name: item.name};
      });

      this.option.xAxis.data = this.getData()
          .dataSets[0].data.slice()
          .map((item) => {
            let date = item.xValue.split(/\D/);
            date = new Date(date[0], --date[1], date[2]);
            return `${Vue.filter('monat')(date.getUTCMonth())} ${date.getUTCFullYear()}`;
          });
      this.option.xAxis.data.reverse();
      const series = [];
      let firstSerie = {};
      let secondSerie = {};

      firstSerie.name = this.getData().dataSets.map((item) => item.name)[0];
      firstSerie.data = this.getData().dataSets[0].data.map((item) => {
        return item.yValue == null ? item.yValue : Number(item.yValue.toFixed(2));
      });
      firstSerie.data.reverse();
      firstSerie.type =
          firstSerie.data.filter((item) => typeof item === "number").length > 1
              ? "line"
              : "scatter";
      firstSerie.color = '';
      series.push(firstSerie);
      secondSerie.name = this.getData().dataSets.map((item) => item.name)[1];
      secondSerie.data = this.getData()
          .dataSets[1].data.map((item) => {
        return item.yValue == null ? item.yValue : Number(item.yValue?.toFixed(2));
      });
      secondSerie.data.reverse();

      secondSerie.type =
          secondSerie.data.filter((item) => typeof item === "number").length > 1
              ? "line"
              : "scatter";
      secondSerie.color = 'orange';
      secondSerie.markPoint = marker;
      series.push(secondSerie);

      this.option.series = series;

      let min = Math.min(
          ...this.option.series[0].data.filter(val => val !== null),
          ...this.option.series[1].data.filter(val => val !== null),
      );
      this.option.yAxis.min = (min / 1.2).toFixed(2);
    }
  }
}
</script>

