<template>
  <Overlay>
    <h1 slot="header">Passwort vergessen?</h1>

    <div slot="body">
      <div class="description">
        Geben Sie Ihre bei der Anmeldung hinterlegte E-Mail Adresse ein. Wir schicken Ihnen ein E-Mail zu, die es Ihnen
        ermöglicht, ein neues Passwort anzulegen.
      </div>
      <div class="loginform">
        <form @submit="processForm">

          <span class="error__main" v-if="errors.general">{{ errors.general }}</span>
          <label>
            <span class="label">E-Mail</span>
            <input type="email" name="email" v-model="email">
            <span class="error" v-if="errors.email">{{ errors.email }}</span>
          </label>
          <br>
          {{ message }}
          <button class="button--primary">Anforderung absenden</button>
        </form>
      </div>
    </div>
  </Overlay>
</template>

<script>
  import Overlay from "../../components/Overlay";
  import axios from 'axios';
  import RequestError from "../../global/errors/RequestError";
  import {VUE_APP_AUTHBASE} from "@/utils/constants";

  let loginclient = axios.create();

  export default {
    name: "ForgotPassword",
    data: () => ({
      errors: {email:'', general:''},
      email: '',
      message: ''
    }),
    components: {
      Overlay
    },
    methods:{
      processForm: function(e){
        e.preventDefault();
        this.errors = [];
        if(!this.email) {
          this.errors.general = 'Bitte überprüfen Sie Ihre Angaben';
          this.errors.email = 'Bitte E-Mail angeben';
        }
        if(!this.errors.length) {
          loginclient.put(VUE_APP_AUTHBASE + 'api/public/users/password-forgotten', {
              email: this.email,
              role: 'editor'
          })
          .then((response) => {
            if (response.status !== 200) {
              throw new RequestError('Something went wrong!');
            }
            if(response.data.error){
              this.message = response.data.error.message;
            } else {
              this.message = "Bitte überprüfen Sie Ihren E-Mail Eingang.";
            }
          })
          .catch((error) => {
            this.message = error.message;
            throw new RequestError(error.message);
          });

        }
      }
    }
  }
</script>

<style scoped>
  .description {
    padding-top: 20px;
  }

  .loginform {
    padding-top: 20px;
  }

  input {
    width: 100%;
    line-height: 30px;
    border: 2px solid lightgrey;
  }

  label {
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    display: inline-block;
    text-align: left;
    width: 100%;
    margin-bottom: 20px;
  }

  .label {
    margin-left: 10px;
  }

  .error, .error__main {
    color: darkred;
  }
  .error{
    font-weight: 400;
    font-size: 14.5px;
  }
  .error__main {
    font-weight: 600;
    font-size: 14.5px;
  }
  .button--primary {
    width: 100%;
  }
</style>
