import { Line } from 'vue-chartjs'
import moment from 'moment';

export default {
  extends: Line,
  props: {
    config: Object,
    datasets: Array,
    stats: Object,
    options: Object,
  },
  watch: {
    datasets: {
      deep: true,
      handler(newData) {
        this.datasets = newData;
        this.showChart();
      }
    }
  },
  data () {
    return {
      chartColors: null,
      chartOptions: {},
      chartData: []
    }
  },
  mounted () {
    moment.locale('de-DE');
    this.showChart();
  },
  methods: {
    showChart: function(){
      this.setChartColors();
      this.setChartData();
      this.setChartOptions();
      this.renderChart(this.chartData, this.chartOptions);
      if(this.datasets.length > 1) {
        this.renderLegend()
      }
    },
    setChartColors: function() {
      let context = this.$refs.canvas.getContext('2d');
      let colorOrder = getColorOrder(this.options, this.datasets);
      this.chartColors = getColors(context, colorOrder, [0,0,0, (this.$refs.canvas.height - 20)]);
    },
    setChartData: function() {
      this.chartData = {
        datasets: createDatasets(this.datasets, this.chartColors)
      }
    },
    setChartOptions: function() {
      this.chartOptions = {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          display: false,
        },
        scales: {
          xAxes: formatXAxis(this.options, this.stats),
          yAxes: formatYAxes(this.options, this.stats, this.config.ygrid)
        },
        tooltips: {
          enabled: true,
          mode: 'nearest',
          intersect: false,
          callbacks: tooltipsCallbacks(this.options)
        }
      }
    },
    renderLegend: function() {
      let colorOrders = getColorOrder(this.options, this.datasets.length);
      let legend = document.createElement('div');
      legend.innerHTML = this.$data._chart.generateLegend();
      let items = legend.getElementsByTagName("ul")[0].getElementsByTagName("li");
      for (let i = 0; i < items.length; ++i) {
        items[i].classList.add(colorOrders[i]);
      }
      this.$emit('generated', legend.innerHTML);
    }

  }

}


/**
 * Color definitions for the chart
 * @type {{border: string, gradient0: string, gradient1: string, fill: string}}
 */
let colorschemes = {
  "orange": {
    border: 'rgba(244, 154, 42, 1)',            // orange #f49a2a
    gradient0: 'rgba(244, 154, 42, 0.75)',      // orange #f49a2a 75%
    gradient1: 'rgba(255, 255, 255, 0.75)',     // white
    fill: 'rgba(244, 154, 42, 0.75)',           // orange #f49a2a 75%
  },
  "blue": {
    border: 'rgba(0, 63, 141, 1)',              // blue #003f8d
    gradient0: 'rgba(0, 63, 141, 0.75)',        // blue #003f8d 75%
    gradient1: 'rgba(255, 255, 255, 0.75)',     // white
    fill: 'rgba(0, 63, 141, 0.75)',             // blue #003f8d 75%
  },
  "green": {
    border: 'rgba(53, 160, 55, 1)',             // green #35A037
    gradient0: 'rgba(53, 160, 55, 0.75)',       // green #35A037 75%
    gradient1: 'rgba(255, 255, 255, 0.75)',     // white
    fill: 'rgba(53, 160, 55, 0.75)',            // green #35A037 75%
  },
  "grey": {
    border: 'rgba(170, 170, 170, 1)',           // grey #aaaaaa
    gradient0: 'rgba(170, 170, 170, 0.75)',     // grey #aaaaaa 75%
    gradient1: 'rgba(255, 255, 255, 0.75)',     // white
    fill: 'rgba(170, 170, 170, 0.75)',          // grey #aaaaaa 75%
  },
  "darkgrey": {
    border: 'rgba(64, 64, 64, 1)',           // grey #aaaaaa
    gradient0: 'rgba(64, 64, 64, 0.75)',     // grey #aaaaaa 75%
    gradient1: 'rgba(255, 255, 255, 0.75)',     // white
    fill: 'rgba(64, 64, 64, 0.75)',          // grey #aaaaaa 75%
  },
  "yellow": {
    border: 'rgba(250, 200, 20, 1)',           // grey #aaaaaa
    gradient0: 'rgba(250, 200, 20, 0.75)',     // grey #aaaaaa 75%
    gradient1: 'rgba(255, 255, 255, 0.75)',     // white
    fill: 'rgba(250, 200, 20, 0.75)',          // grey #aaaaaa 75%
  },
};
let defaultColorOrder = ["blue", "orange"];   // the first color is for the largest dataset > in the background
let defaultSingleColor = ["orange"];

/**
 * Helper functions or callbacks for Colors, Datasets, Axes, Tooltip
 */
function getColorOrder(options, datasets) {
  let colorOrder;
  if('linecolors' in options) {
    if(datasets.length <= options.linecolors.length) {
      colorOrder = options.linecolors;
    }
    else {
      colorOrder = datasets.length === 1 ? defaultSingleColor : defaultColorOrder
    }
  }
  else {
    colorOrder = datasets.length === 1 ? defaultSingleColor : defaultColorOrder
  }
  return colorOrder;
}

function getColors(context, colorNames, coords) {
  let colors = [];

  for(let colorschema of colorNames){
    let schema = colorschemes[colorschema];
    let background = null;
    if(schema.gradient0) {
      background = context.createLinearGradient(coords[0], coords[1], coords[2], coords[3]);
      background.addColorStop(0,schema.gradient0);
      background.addColorStop(1,schema.gradient1);
    }
    else {
      background = schema.fill;
    }
    colors.push({
      name: colorschema,
      border: schema.border,
      background: background
    });
  }
  return colors;
}

function createDatasets(datasets, colors) {
  let sets = [];
  let index = 0;
  if('weight' in datasets[0]) {
      datasets.sort((a, b) => {
      return a.weight > b.weight ? 1 : a.weight < b.weight ? -1 : 0;
    });
  }
  datasets.forEach(function(set) {
    sets.push({
      label: set.label,
      data: set.points,
      backgroundColor: colors[index].background,
      borderColor: colors[index].border,
      borderWidth: 2,
      steppedLine: true,
      pointRadius: 0,
    });
    index++;
  });
  return sets
}

function formatYAxes(options, stats, lines=3) {
  let settings = {};
  if(options.hasOwnProperty('ysteps')) {
    settings['stepSize'] = options.ysteps;
  } else {
    if(stats.hasOwnProperty('range')) {

      settings['stepSize'] = Math.round((stats.range / lines) * 100) / 100;
      settings['min'] = Math.floor(stats.yMin / settings['stepSize'] - settings['stepSize'] / 5 ) * settings['stepSize'];
      settings['max'] = Math.ceil(stats.yMax / settings['stepSize'] + settings['stepSize'] / 5 ) * settings['stepSize'];

      if(stats.range < 0.02) {
        settings['stepSize'] = 0.01;
        settings['min'] = Math.floor(stats.yMin / settings['stepSize'] - settings['stepSize'] / 5) * settings['stepSize'];
        settings['max'] = Math.ceil(stats.yMax / settings['stepSize'] + settings['stepSize'] / 5) * settings['stepSize'];
      }

    }
  }

  let labeltype = options.hasOwnProperty('ylabeltype') ? options.ylabeltype : 'percentage';
  let labelprecision = options.hasOwnProperty('ylabelprecision') ? options.ylabelprecision : 2;
  if(labeltype === 'percentage') {
    settings['callback'] = function(tick) {
      return tick.toLocaleString('de-DE', { minimumFractionDigits: labelprecision }) + ' %';
    };
  }
  if(labeltype === 'currency') {
    settings['callback'] = function(tick) {
      return tick.toLocaleString('de-DE', { minimumFractionDigits: 0 }) + ' €';
    };
  }

  return [{
    ticks: settings
  }]
}

function formatXAxis(options, stats) {
  let period = '';
  if(options.hasOwnProperty('period')) {
    period = options.period;
  }
  else {
    period = 'quarter';
    if(stats.hasOwnProperty('days')) {
      if(stats.days < 182) {
        period = 'month';
      }
    }
  }
  switch (period) {
    case 'quarter':
      return [{
        type:  'time',
        time: {
          unit: 'quarter',
          unitStepSize: 1,
          displayFormats: {
           'quarter': 'MMM \'YY'
          }
        }
      }];
    case 'month':
      return [{
        type:  'time',
        time: {
          unit: 'month',
          unitStepSize: 1,
          displayFormats: {
           'month': 'MMM \'YY'
          }
        }
      }];
      case 'year':
      return [{
        type:  'time',
        time: {
          unit: 'year',
          unitStepSize: 1,
          displayFormats: {
           'year': '\'YY'
          }
        }
      }];
    default:
      return []
  }
}

function tooltipsCallbacks(options) {
  return {
    label: function(tooltipItem) {
      let labeltype = options.hasOwnProperty('ylabeltype') ? options.ylabeltype : 'percentage';
      if(labeltype === 'percentage') {
        return " " + tooltipItem.value.toLocaleString('de-DE', { minimumFractionDigits: 2 }) + ' %';
      }
      if(labeltype === 'currency'){
        return " " + tooltipItem.value.toLocaleString('de-DE', { minimumFractionDigits: 0 }) + ' €';
      }
      return " " + tooltipItem.value;
    },
    title: function(tooltipItem, data) {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return data.datasets[tooltipItem[0].datasetIndex].data[tooltipItem[0].index].x.toLocaleDateString('de-DE', options);
    }
  }
}
