<template>
	<div>
		<p v-html="formattedString">
      <span v-html="formattedString"> </span>
			<span>foo
				<a :href="link" id="readmore" v-show="!isReadMore" v-on:click="triggerReadMore($event, true)">{{moreStr}}</a>
				<a :href="link" id="readless" v-show="isReadMore" v-on:click="triggerReadMore($event, false)">{{lessStr}}</a>
			</span>
		</p>
	</div>
</template>

<script>
export default {
  name: "Readmore",
  props: {
    moreStr: {
      type: String,
      default: "mehr..."
    },
    lessStr: {
      type: String,
      default: "weniger..."
    },
    text: {
      type: String,
      required: true
    },
    link: {
      type: String,
      default: "#"
    },
    maxChars: {
      type: Number,
      default: 100
    }
  },

  data() {
    return {
      isReadMore: false
    };
  },

  computed: {
    formattedString() {
      var val_container = this.text;

      if (!this.isReadMore && this.text.length > this.maxChars) {
        val_container = val_container.substring(0, this.maxChars) + "...";
      }

      return val_container;
    }
  },

  methods: {
    triggerReadMore(e, b) {
      if (this.link == "#") {
        e.preventDefault();
      }
      if (this.lessStr !== null || this.lessStr !== "") this.isReadMore = b;
    }
  }
};
</script>

<style scoped>

</style>
