<template>
  <div>
    <transition name="slide-and-fade">
      <div class="segment__content no-button">
        <div class="content_center" v-if="hasEntries">
          <table class="content__table-iib">
            <tr v-for="(item, index) in priceTable" :key="index">
              <td class="item__class">{{ item.class }}</td>
              <td class="item__price" :class="tendence(item.tendence)">{{ item.price|int }} €/m<sup>2</sup></td>
            </tr>
          </table>
          <div class="content__center" v-if="mieterschwinglichkeit">
            Ein durchschnittlicher 2-Personen-Haushalt*<br/>
            kann sich in {{ location }} <br/>
            <strong>{{ mieterschwinglichkeit }} m<sup>2</sup> Wohnfläche leisten.</strong><br/>
          </div>
        </div>

        <div class="segment__noentries" v-else>Keine Daten</div>

      </div>
    </transition>
  </div>
</template>

<script>
  import IibBaseTile from './IibBaseTile'

  export default {
    name: "IibPreiseTile",
    extends: IibBaseTile,
    props: {
      data: Object
    },
    computed: {
      hasEntries: function () {
        return this.data && this.data.entries && this.data.entries.length > 0;
      },
      entries: function () {
        return this.data.entries;
      },
      location: function () {
        return this.priceEntry.name
        //return this.localizator.location
      },
      priceEntry: function () {
        return this.entries[0];
      },
      mieterschwinglichkeit: function () {
        return this.hasEntries && this.priceEntry.mieterschwinglichkeit;
      },
      cheapRow: function () {
        return {
          "class": "günstig",
          "price": this.priceEntry.angebotspreise_monat_guenstig,
          "tendence": this.priceEntry.angebotspreise_monat_guenstig_tendenz
        }
      },
      fairRow: function () {
        return {
          "class": "mittel",
          "price": this.priceEntry.angebotspreise_monat_fair,
          "tendence": this.priceEntry.angebotspreise_monat_fair_tendenz
        }
      },
      expensiveRow: function () {
        return {
          "class": "teuer",
          "price": this.priceEntry.angebotspreise_monat_teuer,
          "tendence": this.priceEntry.angebotspreise_monat_teuer_tendenz
        }
      },
      priceTable: function () {
        return [
          this.cheapRow,
          this.fairRow,
          this.expensiveRow
        ]
      },
      densityData: function () {
        let density = this.priceEntry.angebote_haushalte;
        let label = density > 1.1 ? "üppig" : density < 0.9 ? "knapp" : "ausgewogen";
        let color = density > 1.1 ? "greenlight" : density < 0.9 ? "redlight" : "yellowlight";
        return {
          "density": this.priceEntry.angebote_haushalte,
          "color": color,
          "label": label
        }
      }
    },
    methods: {
      tendence: function (value) {
        return 'iib-tendenz-' + value
      }
    }
  }
</script>

<style lang="scss">


</style>
