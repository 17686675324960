<template>
  <Overlay>
    <h1 slot="header">Anmeldung</h1>

    <div slot="body">
      <div class="description">
        Melden Sie sich mit Ihren Zugangsdaten an
      </div>
      <div class="loginform">
        <form @submit="processForm">
          <span class="error__main" v-if="errors.general">{{ errors.general }}</span>
          <label>
            <span class="label">E-Mail</span>
            <input type="email" name="email" v-model="email">
            <span class="error" v-if="errors.email">{{ errors.email }}</span>
          </label>

          <br>
          <label>
            <span class="label">Passwort</span>
            <input type="password" name="password" v-model="password">
            <span class="error" v-if="errors.password">{{ errors.password }}</span>
          </label>
          <br>
          {{message}}
          <button class="button--primary" @click="processForm">Login</button>
        </form>
        <br>
        <a href="#" @click.prevent="$emit('switchForgot')">&raquo; Oder Passwort vergessen?</a><br>

        <div class="hint">
          <h3>Noch kein Konto?</h3>
          Jetzt registrieren und unseren Redaktionsservice in vollem Umfang nutzen. <br>
          <a href="#" @click.prevent="$emit('switchRegister')">&raquo; Zur Registrierung</a>
        </div>
      </div>
    </div>
  </Overlay>
</template>

<script>
  import Overlay from "../../components/Overlay";
  import axios from 'axios';
  import RequestError from "../../global/errors/RequestError";
  import {mapActions} from 'vuex';
  import {VUE_APP_AUTHBASE} from "@/utils/constants";

  let loginclient = axios.create();

  export default {
    name: "Login",
    data: () => ({
      errors: {email:'', password:'', general:''},
      email: '',
      password: '',
      message: ''
    }),
    components: {
      Overlay
    },
    methods:{
      ...mapActions([
        'closeAllLayers'
      ]),
      processForm: function(e) {
        e.preventDefault();
        this.errors = [];
        if(!this.email) {
          this.errors.general = 'Bitte überprüfen Sie Ihre Angaben';
          this.errors.email = 'Bitte E-Mail angeben';
        }
        if(!this.password) {
          this.errors.general = 'Bitte überprüfen Sie Ihre Angaben';
          this.errors.password = 'Bitte Passwort angeben';
        }
        if(!this.errors.length) {
          loginclient.post(VUE_APP_AUTHBASE + 'api/public/sessions/login', {
              email: this.email,
              password: this.password,
              role: 'editor',
              returnUrl: process.env.DASHBOARDURL
          })
          .then((response) => {
            if (response.status !== 200) {
              throw new RequestError('Something went wrong!');
            }
            if (response.data.error) {
              this.message = response.data.error.message;
            } else {
              // remove layers
              this.$router.push('/dashboard?token=' + response.data.token); //TODO: add tracking code
              this.closeAllLayers();
              localStorage.removeItem('stage');
            }

          })
          .catch((error) => {
            throw new RequestError(error.message);
          });

        }
      }
    }
  }
</script>

<style scoped>
  .description {
    padding-top: 20px;
  }

  .loginform {
    padding-top: 20px;
  }

  input {
    width: 100%;
    line-height: 30px;
    border: 2px solid lightgrey;
  }

  label {
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    display: inline-block;
    text-align: left;
    width: 100%;
    margin-bottom: 20px;
  }

  .label {
    margin-left: 10px;
  }

  .error, .error__main {
    color: darkred;
  }
  .error{
    font-weight: 400;
    font-size: 14.5px;
  }
  .error__main {
    font-weight: 600;
    font-size: 14.5px;
  }
  .button--primary {
    width: 100%;
  }
</style>
