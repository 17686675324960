<template>
  <div class="card__double card-chart">
    <div class="segment__title" v-if="hasData">{{ config.title }}</div>
    <div class="segment__chart" v-if="hasData">
      <line-chart :datasets="datasets"
                  :options="options"
                  :stats="elementData.stats"
                  :config="{
                    chartid: chartid,
                    ygrid: 3
                  }"
                  :width="369"
                  :height="184"
                  :css-classes="'chart__component'"
                  @generated="setLegend"
      ></line-chart>
      <div class="chart__legend"
           v-if="legend" v-html="legend"
      ></div>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapGetters, mapMutations} from 'vuex'
  import LineChart from './shared/LineChart.js';

  export default {
    name: "Chart",
    components: {
      LineChart
    },
    props: {
      element: Object
    },
    data() {
      return {
        legend: null
      }
    },
    computed: {
      ...mapGetters([
        'elements'
      ]),
      hasData: function () {
        return Object.keys(this.elementData).length > 0;
      },
      elementData: function () {
        return this.getElement(this.element.id);
      },
      config: function () {
        return this.elementData.extras.chart_config;
      },
      options: function () {
        return this.config && 'options' in this.config ? this.config.options : {}
      },
      chartid: function () {
        return this.config && 'id' in this.config ? this.config.id : 'chart-' + Math.round(Math.random() * 10000000)
      },
      datasets: function () {
        if (this.elementData.datasets) {
          let datasets = [];
          for (let dataset of this.elementData.datasets) {
            let points = [];
            for (let item of dataset.Data) {
              points.push({
                x: new Date(item.XValue),
                y: item.YValue
              })
            }
            datasets.push({
              label: dataset['Name'],
              points: points,
              weight: dataset['Sum']
            });
          }
          return datasets;
        } else {
          return [];
        }
      },
    },
    methods: {
      ...mapActions([
        'loadElement',
        'loadData'
      ]),
      ...mapMutations([
        'addElement'
      ]),
      setLegend(html) {
        this.legend = html
      },
      getElement(id) {
        let element = this.elements.find(element => element.id === id);
        return element ? element : {}
      },
      notEmpty(object) {
        return Object.keys(object).length > 0
      }
    },
    mounted() {
      if (this.element.loadasync && this.element.dataurl) {
        this.loadData(this.element.dataurl);
      } else {
        this.addElement(this.element.data)
      }
    },
  }
</script>

