<template>
   <a :href="url" target="_blank">
      <button class="more-button">{{ text }}</button>
   </a>
</template>

<script>

  export default {
    name: "MoreButton",
    props : {
      config: Object
    },
    computed: {
      text: function () {
        return 'text' in this.config ? this.config.text : 'Mehr';
      },
      url: function () {
        return 'url' in this.config ? this.config.url : '/';
      },
    },
    methods: {
    }
  }
</script>

