<template>
  <div class="editorscorner__topangebote" v-if="page" :key="page_id">

    <buttonbar :name="buttonbar" :token="token" :service="service"></buttonbar>

    <div class="page__domain flavour--fmh">
      <div class="section__group"
        v-for="(section, index) in sections"
        :key="index"
      >
        <h3 class="section__title" v-if="section.headline1">{{ section.headline1 }}</h3>
        <h4 class="section__subtitle" v-if="section.headline2">{{ section.headline2 }}</h4>
        <p class="section__infotext" v-if="section.infotext">{{ section.infotext }}</p>
        <div class="topangebote__overview" v-if="cardLikeElements(section.elements)">
          <div class="common__cards">
            <fragment  v-for="(element, index) in cardLikeElements(section.elements)" :key="index">
              <TopCardsElement :element="element" v-if="element.elementtype===enumElementtypes.CARD" />
              <Chart :element="element" v-if="element.elementtype===enumElementtypes.CHART" />
            </fragment>
          </div>
        </div>
        <div class="topangebote__fullist" v-if="showSimpleTable(section.elements)">
          <div class="section__table"
            v-for="(element, index) in tableElements(section.elements)"
            :key="index"
            >
            <TableElement
              :element="element"
              :tendency-colors="section.tendency_colors"
            ></TableElement>
          </div>
        </div>
        <div class="topangebote__fullist" v-if="showTableGroups(section.elements)">
          <div class="common__tablegroup">
            <div class="tablegroup__wrapper"
              v-for="(tablegroup, index) in tableGroups(section.elements)"
              :key="index"
            >
              <div class="group__title">
                <h3>{{ tablegroup.grouptitle }}</h3>
              </div>
              <div class="group__subtables">
                <div class="subtable__box"
                  v-for="(grouptable, index) in tablegroup.grouptables"
                  :key="index"
                >
                  <div class="subtable__emphasised" v-html="grouptable.description"></div>
                  <TableElement
                    :element="grouptable"
                    :tendency-colors="section.tendency_colors"
                    :only-bankname="true"
                  ></TableElement>
                </div>
              </div>
            </div>
          </div>
        </div>
        <buttonbar :name="buttonbar" :token="token" :service="service"></buttonbar>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { Fragment } from 'vue-fragment'
  import Buttonbar from "../elements/shared/Buttonbar";
  import TableElement from "../elements/TableElement";
  import TopCardsElement from "../elements/TopCardsElement";
  import Chart from "../elements/Chart";
  import enums from '@/mixins/enums';
  import cms from '@/mixins/cms';

  export default {
    name: 'Topangebote',
    mixins: [enums, cms],
    props: {
      service: String
    },
    components: {
      Fragment,
      Buttonbar,
      TableElement,
      TopCardsElement,
      Chart
    },
    data() {
      return { }
    },
    computed: {
      ...mapGetters([
        'pages',
        'getPageById'
      ]),
      page_id: function () {
        return this.service + '.topangebote';
      },
      domain_id: function() {
        return "FMH"; // TODO: make it dynamic when implementing IIB
      },
      token: function () {
        return 'token' in this.$route.query ? this.$route.query.token : '';
      },
      buttonbar: function () {
        return 'topangebote'
      },
      page: function() {
        let page = this.getPageById(this.page_id);
        if(!page) {
          this.loadPage({page_id: this.page_id});
        }
        return page;
      },
      domain: function() {
        if(this.page) {
          return this.page.domains.find(domain => domain.domain_id === this.domain_id);
        }
        return {};
      },
      sections: function () {
        return this.domain.sections
      }
    },

    methods: {
      ...mapActions([
        'loadPage'
      ]),
      cardLikeElements: function (elements) {
        return elements.filter(element => element.elementtype === this.enumElementtypes.CARD
          || element.elementtype === this.enumElementtypes.CHART);
      },
      tableElements: function (elements) {
        return elements.filter(element => element.elementtype === this.enumElementtypes.TABLE);
      },
      tableGroups: function (elements) {
        let tableElements = this.tableElements(elements);
        let tableGroups = [];
        let lastGrouptitle = "";
        let groupTables = [];
        for(let table of tableElements) {
          if('tablegroup' in table.extras) {
            // first table
            if(lastGrouptitle === "") {
              lastGrouptitle = table.extras.tablegroup.grouptitle;
            }
            // continuous tables
            if(table.extras.tablegroup.grouptitle === lastGrouptitle) {
              table['description'] = table.extras.tablegroup.description;
              groupTables.push(table);
            }
            else {
              tableGroups.push({
                grouptitle: lastGrouptitle,
                grouptables: groupTables
              });
              groupTables = [];
              table['description'] = table.extras.tablegroup.description;
              groupTables.push(table);
              lastGrouptitle = table.extras.tablegroup.grouptitle;
            }
          }
        }
        // last table, if any
          if(lastGrouptitle !== "" && groupTables.length > 0) {
          tableGroups.push({
            grouptitle: lastGrouptitle,
            grouptables: groupTables
          });
        }
        return tableGroups;
      },
      showTableGroups: function (elements) {
        return this.tableGroups(elements).length > 0;
      },
      showSimpleTable: function (elements) {
        return this.tableElements(elements).length > 0 && this.tableGroups(elements).length === 0;
      }
    },
    updated() {
      if(this.page) {
        this.emitCmsTopic(this.page.cms_topic_id);
      }
    },
    mounted() {
      if(this.page) {
        this.emitCmsTopic(this.page.cms_topic_id);
      }
    }
  }
</script>


