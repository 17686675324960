import AbstractAPI from '@/global/apis/AbstractAPI';

export default class EditorsAPI extends AbstractAPI {

  getDashboard() {
    return this.client.request({
      method: 'get',
      url: 'editorscorner/api/page/dashboard',
    })
    .then(data => data);
  }

  getElement(id) {
    return this.client.request({
      method: 'get',
      url: 'editorscorner/api/element/' + id,
    })
    .then(data => data);
  }

  getDatUrl(url) {
    return this.client.request({
      method: 'get',
      url: url,
    })
    .then(data => data);
  }

  getDatUrlLoc(url, localizator) {
    return this.client.request({
      method: 'get',
      //url: `${url}?standort=${JSON.stringify(localizator.geometry)}`,
      url: `${url}?kgs=${localizator.kgs}`,
    })
    .then(data => data);
  }

  getLocation(querystring){
    return this.client.request({
      method: 'get',
      url: 'editorscorner/api/geocode/' + querystring,
    })
    .then(data => data);
  }

  getPage(page_id) {
    return this.client.request({
      method: 'get',
      url: 'editorscorner/api/page/' + page_id,
    })
    .then(data => data[page_id]);
  }

  getTopic(template_id) {
    return this.client.request({
      method: 'get',
      url: 'editorscorner/api/topic/' + template_id,
    })
    .then(data => data);
  }

  userAuth(token, page) {
    return this.client.request({
      method: 'get',
      url: 'editorscorner/api/user/auth/' + token + '/page/' + page,
    })
    .then(data => data);
  }

  getEnum(type) {
    return this.client.request({
      method: 'get',
      url: 'editorscorner/api/enum/' + type,
    })
    .then(data => data);
  }
}
