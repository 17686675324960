<template>
  <div class="segment__chart" v-if="hasDatasets">
    <div class="chart__component">
      <line-chart :datasets="datasets"
                  :options="options"
                  :stats="data.stats"
                  :config="{
                    chartid: chartid,
                    ygrid: 3
                  }"
                  :width="280"
                  :height="150"
                  @generated="setLegend"
      ></line-chart>
    </div>
    <div class="chart__legend"
        v-if="legend" v-html="legend"
    ></div>
  </div>
</template>

<script>
  import LineChart from '../shared/LineChart.js';

  export default {
    name: "FmhChartTile",
    components: {
      LineChart
    },
    props: {
      data: Object
    },
    data () {
      return {
        legend: null
      }
    },
    computed: {
      hasDatasets: function() {
        return this.data && this.data.datasets && this.data.datasets.length > 0
      },
      options: function() {
        return 'options' in this.data.config ? this.data.config.options : {}
      },
      chartid: function() {
        return 'id' in this.data.config ? this.data.config.id : 'chart-' + Math.round(Math.random()*10000000)
      },
      datasets: function() {
        if ( this.data.datasets ) {
          let datasets = [];
          for(let dataset of this.data.datasets) {
            let points = [];
             for(let item of dataset.Data) {
               points.push({
                 x: new Date(item.XValue),
                 y: item.YValue
               })
             }
            datasets.push({
              label: dataset['Name'],
              points: points,
              weight: dataset['Sum']
            });
          }
          return datasets;
        }
        else {
          return [];
        }
      },
    },
     methods: {
      setLegend (html) {
        this.legend = html
      }
    }
  }
</script>

