export default {

  isLoading: state => state.loading,

  dashboard: state => state.dashboard,

  notifications: state => state.notifications,

  elements: state => state.elements,

  pages: state => state.pages,

  getPageById: state => page_id => state.pages[page_id],

  pageExists: state => page_id => page_id in state.pages,

  topics: state => state.topics,

  enums: state => state.enums,

  enumByType: state => type => state.enums[type],

  localizator: state => state.localizator,

  showModal: state => state.showInfolayer,

  showRegistrationLayer: state => state.showRegistrationLayer,

  isModal: state => state.modal,

  showLogin: state => state.showLogin,

  graphs: state => state.graphs
};
