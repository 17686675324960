<template>
  <div class="editorscorner__zinsen" v-if="page" :key="page_id">

    <buttonbar :name="buttonbar" :token="token" :service="service"></buttonbar>

    <div class="page__domain flavour--fmh">
      <div class="section__group"
        v-for="(section, index) in sections"
        :key="index"
      >
        <h3 class="section__title" v-if="section.headline1">{{ section.headline1 }}</h3>
        <h4 class="section__subtitle" v-if="section.headline2">{{ section.headline2 }} (max. {{ section.amount_changed }} mögliche Zinsänderungen)</h4>
        <p class="section__infotext" v-if="section.infotext">{{ section.infotext }}</p>
        <div class="section__table"
             v-for="(element, index) in section.elements"
             :key="index"
        >
          <TableElement v-if="element.elementtype === enumElementtypes.TABLE"
                        :element="element"
                        :tendency-colors="section.tendency_colors"
                        :access-group="accessGroup"
          />
          <StatsCardsElement v-if="element.elementtype === enumElementtypes.CARD"
                             :element="element"
                             :tendency-colors="section.tendency_colors"
          />
          <buttonbar :name="buttonbar" :token="token" :service="service" v-if="entryCount(element.id) > 4" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Buttonbar from "../elements/shared/Buttonbar";
  import TableElement from "../elements/TableElement";
  import StatsCardsElement from "../elements/StatsCardsElement";
  import enums from '@/mixins/enums';
  import cms from '@/mixins/cms';

  export default {
    name: 'Zinsen',
    mixins: [enums, cms],
    props: {
      service: String,
      period: String,
    },
    components: {
      Buttonbar,
      TableElement,
      StatsCardsElement
    },
    data() {
      return {
        allEntriesCnt: new Map()
      }
    },
    computed: {
      ...mapGetters([
        'pages',
        'getPageById',
        'elements'
      ]),
      page_id: function () {
        return this.service + '.zinsen-' + this.period;
      },
      domain_id: function() {
        return "FMH"; // TODO: make it dynamic when implementing IIB
      },
      token: function () {
        return 'token' in this.$route.query ? this.$route.query.token : '';
      },
      accessGroup: function() {
        return 'g' in this.$route.query ? this.$route.query.g : '';
      },
      buttonbar: function () {
        return 'zinsen-' + this.period
      },
      page:  function() {
        let page = this.getPageById(this.page_id);
        if(!page) {
          this.loadPage({page_id: this.page_id});
        }
        return page;
      },
      domain: function() {
        if(this.page) {
          return this.page.domains.find(domain => domain.domain_id === this.domain_id);
        }
        return {};
      },
      sections: function () {
        return this.domain.sections
      }
    },

    methods: {
      ...mapActions([
        'loadPage'
      ]),
      getElement(id) {
        let element = this.elements.find(element => element.id === id);
        return element ? element : {}
      },
      entryCount: function(elementConfigId) {
        let element = this.getElement(elementConfigId);
        let ret = 0;
        if(element.entries && element.entries.length > 0)
          ret = element.entries.length;
        this.allEntriesCnt.set(elementConfigId, ret);
        return ret;
      }
    },
    updated() {
      if(this.page) {
        this.emitCmsTopic(this.page.cms_topic_id);
      }
    },
    mounted() {
      if(this.page) {
        this.emitCmsTopic(this.page.cms_topic_id);
      }
    }
  }
</script>


