import Vue from 'vue';

let locale = 'de-DE';
Vue.prototype.$filters = Vue.options.filters;

Vue.filter('percentage', value => {
  let percents = value/100;
  return new Intl.NumberFormat(locale, {style: 'percent', minimumFractionDigits: 3}).format(percents);
});

Vue.filter('percentage2', value => {
  let percents = value/100;
  return new Intl.NumberFormat(locale, {style: 'percent', minimumFractionDigits: 2}).format(percents);
});

Vue.filter('percentage0', value => {
  let percents = value/100;
  return new Intl.NumberFormat(locale, {style: 'percent', minimumFractionDigits: 0}).format(percents);
});

Vue.filter('shortdate', value => {
  let date = Date.parse(value);
  return new Intl.DateTimeFormat(locale, {day: '2-digit', month: '2-digit'}).format(date);
});

Vue.filter('date', value => {
  let date = Date.parse(value);
  return new Intl.DateTimeFormat(locale, {day: '2-digit', month: '2-digit', year: 'numeric'}).format(date);
});

Vue.filter('currency', (value, digits=0) => {
  return new Intl.NumberFormat(locale, {style: 'currency', currency: 'EUR', minimumFractionDigits: digits}).format(value);
});

Vue.filter('currency2', (value) => {
  return Vue.filter('currency')(value, 2);
});

Vue.filter('duration', (value, yearsOnly=false) => {
  let period = value.slice(-1);
  let number = value.slice(0, -1);
  if(period === 'D') {
    return number === '1'  ? number + " Tag" : number + " Tage"
  }
  if(period === 'D') {
    return number === '1'  ? number + " Tag" : number + " Tage"
  }
  if(period === 'M' && number === '12' && yearsOnly) {
    return "1 Jahr"
  }
  if(period === 'M') {
    return number === '1'  ? number + " Monat" : number + " Monate"
  }
  if(period === 'Y') {
    return number === '1'  ? number + " Jahr" : number + " Jahre"
  }
  return value
});
Vue.filter('durationY', value => {
  return Vue.filter('duration')(value, true);
});

Vue.filter('regional', value => {
  return value ? "Regional" : "Bundesweit";
});

Vue.filter('tendence', value => {
  return value >= 0 ? "good" : "bad";
});

Vue.filter('arrow', value => {
  return value >= 0 ? "up" : "down";
});

Vue.filter('boolicon', bool => {
  return bool ? '<span class="boolicon check">Ja</span>' : '<span class="boolicon cancel">Nein</span>';
});

Vue.filter('boolean', bool => {
  return bool ? 'Ja' : 'Nein';
});

Vue.filter('rang', (item, display=null) => {
  let value = item['index'];
  if (display !== null && 'rang' in display) {
    if('field' in display['rang']) {
      value = item[display['rang']['field']];
    }
    if('placeholder' in display['rang']) {
      value = display['rang']['placeholder'].replace('{}', value);
      // or: https://coderwall.com/p/flonoa/simple-string-format-in-javascript
      // or: https://www.npmjs.com/package/string-format
    }
    if('localize' in display['rang'] && display['rang']['localize']) {
      value = value.replace('Y','J')
    }
  }
  return value;
});

Vue.filter('years', value => {
  return value + " Jahre"
});

Vue.filter('token', (link, token) => {
  if(token) {
    link = '?' in link ? link + '&token=' + token : link + '?token=' + token
  }
  return link
});

Vue.filter('einlagensicherung', einlagensicherung => {
  let result = "";
  for(let es of einlagensicherung) {
    let title = es.Bezeichnung === 'gesetzliche' ? 'gesetzliche Einlagensicherung in ' + es.Landname : es.Bezeichnung + ' Einlagensicherung';
    let src = 'https:' + es.FlaggeUrl;
    result += '<img src="' + src + '" alt="' + es.Landname + '" title="' + title + '"> '
  }
  return result;
});

/* eslint no-unused-vars:off */
Vue.filter('float', (value, digits=2) => {
  return new Intl.NumberFormat(locale, {style: 'decimal', minimumFractionDigits: digits}).format(value);
});
Vue.filter('int', (value) => {
  return new Intl.NumberFormat(locale, {style: 'decimal', maximumFractionDigits: 0}).format(value);
});
Vue.filter('upper', value => {
  return value.toUpperCase()
});
Vue.filter('monat', value => {
  let months = ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"];
  if(typeof value === 'number')
    return months[value];
  return value;
});
