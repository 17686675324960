<template>
  <table class="content__table">
        <tr class="table__item" v-for="(field, index) in extendedFields" :key="index">
          <td class="item__label">{{ field.label }}:</td>
          <td class="item__value" v-if="render_html(field)"  v-html="field_filtered(field)"></td>
          <td class="item__value" v-else>
            {{ field_filtered(field) }}
          </td>
        </tr>
  </table>
</template>

<script>
  import Vue from "vue";

  export default {
    name: "ExtendedCardEntry",
    props: {
      entry: Object,
      fieldgroups: Array,
    },
    components: { },
    computed: {
      extendedFields: function () {
        let fields = [];
        for(let fieldgroup of this.fieldgroups) {
          for(let field of fieldgroup.fields) {
            fields.push(field);
          }
        }
        return fields;
      },
    },
    methods: {
      field_filtered: function (field) {
        if(field.field in this.entry) {
          if('filter' in field) {
            return Vue.filter(field.filter)(this.entry[field.field])
          }
          else {
            return this.entry[field.field];
          }
        }
        else {
          return '-';
        }
      },
      render_html: function (field) {
        return 'renderHtml' in field ? field.renderHtml : false
      }
    }
  }
</script>
