<template>
  <div class="loader"></div>
</template>

<script>
  import {mapActions} from 'vuex';

  export default {
    name: 'Loader',
    props: {
      type: String
    },
    methods: {
      ...mapActions([
        'loadDashboard'
      ])
    },
    mounted: function mounted() {
      this.loadDashboard();
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
