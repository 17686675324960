<script>
  import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
  import enums from '@/mixins/enums'

  export default {
    name: "BaseElement",
    mixins: [enums],
    props: {
      element: Object,
    },
    computed: {
      ...mapGetters([
        'elements',
      ]),
      ...mapState([
        'localizator'
      ]),
      currentElement: function () {
        return this.getElement(this.element.id);
      },
      datatype: function () {
        return this.element.datatype
      },
      hasEntries: function () {
        return this.currentElement.entries && this.currentElement.entries.length > 0;
      },
      entries: function () {
        return this.hasEntries ? this.currentElement.entries : [];
      },
    },
    methods: {
      ...mapActions([
        'loadData'
      ]),
      ...mapMutations([
        'addElement'
      ]),
      getElement(id) {
        let element = this.elements.find(element => element.id === id);
        return element ? element : {}
      }
    },
    mounted() {
      if (this.element.loadasync && this.element.dataurl) {
        this.loadData(this.element.dataurl);
      } else {
        this.addElement(this.element.data)
      }
    }
  }
</script>


