import getEnv from '@/utils/env';

export const BACKEND_URL =
    getEnv('VUE_APP_BACKEND_URL') || 'https://api.editors.fmh.de/';
export const VUE_APP_AUTHBASE =
    getEnv('VUE_APP_AUTHBASE') || 'https://new-users.fmh.de/';

export const DEFAULT_TOP_CARDS = [
    {
    groupkey:'',
    LandRating: '',
    Effektivzins: '',
    BeleihungBis: '',
    Mindesttilgung: '',
    MaximaleBeleihung: '',
    Sollzins: '',
    Bankname: '',
    index: '',
    Produktname: '',
    Zinssatz:'',
    Einlagensicherung: '',
    Zinsen: '',
    Kaufpreis: '',
    Darlehen: ''
},
    {
        groupkey: '',
        LandRating: '',
        Effektivzins: '',
        BeleihungBis: '',
        Mindesttilgung: '',
        MaximaleBeleihung: '',
        Sollzins: '',
        Bankname: '',
        index: '',
        Produktname: '',
        Zinssatz:'',
        Einlagensicherung: '',
        Zinsen: '',
        Kaufpreis: '',
        Darlehen: '' },
    {
        groupkey: '',
        LandRating: '',
        Effektivzins: '',
        BeleihungBis: '',
        Mindesttilgung: '',
        MaximaleBeleihung: '',
        Sollzins: '',
        Bankname: '',
        index: '',
        Produktname: '',
        Zinssatz:'',
        Einlagensicherung: '',
        Zinsen: '',
        Kaufpreis: '',
        Darlehen: '' },
    {
        groupkey: '',
        LandRating: '',
        Effektivzins: '',
        BeleihungBis: '',
        Mindesttilgung: '',
        MaximaleBeleihung: '',
        Sollzins: '',
        Bankname: '',
        index: '',
        Produktname: '',
        Zinssatz:'',
        Einlagensicherung: '',
        Zinsen: '',
        Kaufpreis: '',
        Darlehen: '' }
]
