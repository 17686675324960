<template>
  <div class="entry__bank" v-if="replacewith">
     <div class="bank__names bank_only">
       <div class="bank__name">{{ replacewith }}</div>
     </div>
  </div>
  <div class="entry__bank" v-else>
    <div class="bank__logo" v-if="logoUrl && !onlyBankname">
        <img :src="logoUrl" :title="entry.Bankname" :alt="entry.Bankname">
    </div>
    <div class="bank__names" :class="[ !entry.Produktname ? 'bank_only' : '']">
      <div class="bank__name">{{ entry.Bankname }}{{ entry.VolltilgungAngebot ? " (Volltilger)" : "" }}</div>
      <div class="bank__product" v-if="entry.Produktname">{{ entry.Produktname }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "BankEntry",
    props: {
      entry: Object,
      replacewith: String,
      onlyBankname: Boolean,
    },
    computed: {
      logoUrl: function () {
        return this.entry.BankLogoUrl ? 'https:' + this.entry.BankLogoUrl : ''
      }
    }
  }
</script>

