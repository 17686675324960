const buttonbars = {};

buttonbars['zinsen-thisweek'] = [
  add_nav_button("dashboard", "Dashboard", '/dashboard', 'dashboard'),
  add_nav_button("zinsen", "Zinsen Letzte Woche",'/zinsen-lastweek/<service>', '<service>.zinsen-lastweek'),
  add_nav_button("top", "Topangebote", '/topangebote/<service>', '<service>.topangebote'),
];
buttonbars['zinsen-lastweek'] = [
  add_nav_button("dashboard", "Dashboard", '/dashboard', 'dashboard'),
  add_nav_button("zinsen", "Zinsen Diese Woche",'/zinsen-thisweek/<service>', '<service>.zinsen-thisweek'),
  add_nav_button("top", "Topangebote", '/topangebote/<service>', '<service>.topangebote'),
];
buttonbars['topangebote'] = [
  add_nav_button("dashboard", "Dashboard", '/dashboard', 'dashboard'),
  add_nav_button("zinsen", "Zinsen Diese Woche",'/zinsen-thisweek/<service>', '<service>.zinsen-thisweek'),
  add_nav_button("zinsen", "Zinsen Letzte Woche",'/zinsen-lastweek/<service>', '<service>.zinsen-lastweek'),
];

function add_nav_button(icon, text, route, page_id) {
  return {
    "text": text,
    "route": route,
    "icon": icon,
    "page_id": page_id
  }
}

export default buttonbars;
