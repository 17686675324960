<template>
  <transition name="fade">
    <div class="notification"
         v-show="visible"
         :class="notification.type"
    >
      <div class="icon">
        <font-awesome-icon icon="bomb" v-if="notification.type==='error'"></font-awesome-icon>
        <font-awesome-icon icon="info-circle" v-if="notification.type==='info'"></font-awesome-icon>
        <font-awesome-icon icon="exclamation-triangle" v-if="notification.type==='warning'"></font-awesome-icon>
        <font-awesome-icon icon="check" v-if="notification.type==='success'"></font-awesome-icon>
      </div>
      <div class="message">
        <h4>{{ notification.title }}</h4>
        <pre>{{ notification.text }}</pre>
      </div>
      <div class="buttons">
        <font-awesome-icon :icon="['far', 'times-circle']" class="close" v-on:click="visible=false"></font-awesome-icon>

      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Notification',
  props: {
    notification: Object
  },
  data() {
      return {
          visible: true
      }
  },
  created() {
      setTimeout(() => this.visible = false, 5000)
  }

}
</script>
<style lang="scss">
  .notification {
    border: 1px solid silver;
    padding: 6px;
    border-radius: 4px;
    display: flex;

    &.error {
      border-color: #aa0000;
      background-color: #ffdddddd;
      color: #aa0000;
    }
    &.success {
      border-color: #008000;
      background-color: #eafbea;
      color: #008000;
    }
    &.warning {
      border-color: #a27e00;
      background-color: #fff8ba;
      color: #a27e00;
    }
    &.info {
      border-color: #000080;
      background-color: #dde7ff;
      color: #000080;
    }

    .message {
      width: calc(100% - 56px);
      h4, p {
        margin-top: 0;
        margin-bottom: 6px;
      }
    }
    .icon {
      width: 32px;
    }
    .buttons {
      width: 24px;
      text-align: right;
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
