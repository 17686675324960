<template>
  <div class="dashboard" v-if="dashboardHasTopic">

    <div class="dashboard__sections">
      <div class="section__group flavour--fmh">
        <div class="section__title"
             style="color: white; font-size: 16px; font-weight: bold; background: #1792d0; padding: 20px;">
          Die aktuellsten Themen
        </div>
        <div class="section__scrollist">
          <ul id="scroll-index" class="topic__list">
            <li class="list__headline">
              <strong>Verpasst? Das waren die letzten Redaktionsnewsletter:</strong>
              <h3 class="subline"></h3>
              <fragment v-if="mailchimpItems.length > 0">
                <fragment v-for="(el, index) in mailchimpItems.slice(0,3)" :key="index">
                  <ul class="section__list">
                    <li><a :href="el.url"><span class="arr"></span>{{el.title}}</a>
                    </li>
                  </ul>
                </fragment>
              </fragment>
              <fragment v-else><div class="loader">Loading...</div></fragment>
            </li>
          </ul>
          <ul id="scroll-index" class="topic__list">
            <li class="list__headline iib"><strong>Die aktuellsten FMH-News-Artikel:</strong>
              <h3 class="subline"></h3>
              <fragment v-if="fmhItems.length > 0">
                <fragment v-for="(el, index) in fmhItems.slice(0,3)" :key="index">
                  <ul class="section__list">
                    <li><a :href="el.url"><span class="arr"></span>{{el.title}}</a>
                    </li>
                  </ul>
                </fragment>
              </fragment>
              <fragment v-else><div class="loader">Loading...</div></fragment>
              <ul class="section__list">
                <li><a href="https://www.fmh.de/archiv"><span class="arr"></span>weitere Artikel</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div class="section__group flavour--fmh">
        <div class="section__title"
             style="color: white; font-size: 16px; font-weight: bold; background: #1792d0; padding: 20px;">
          Die aktuellsten Dateien
        </div>
        <div class="section__content">
          <fragment v-if="Object.keys(this.graphs).length === 3">
            <div class="content__tile">
              <h4>Grafik der Woche</h4>
              <WochenChart :chartdata="this.getGraphData('grafikderwoche')" />
              <readmore :text="this.getGraphData('grafikderwoche').infotext" :max-chars="1000"></readmore>
            </div>
            <div class="content__tile">
              <h4>Zinsprognose</h4>
              <PrognoseChart :chartdata="this.getGraphData('prod_2')" />
              <readmore :text="this.getGraphData('prod_2').infotext" :max-chars="1000"></readmore>
            </div>
            <div class="content__tile">
              <h4>Zinsprognose</h4>
              <PrognoseChart :chartdata="this.getGraphData('prod_21')" />
              <readmore :text="this.getGraphData('prod_21').infotext" :max-chars="1000"></readmore>
            </div>
          </fragment>
        </div>
      </div>

      <div class="section__group flavour--fmh">
        <div class="section__title"
             style="color: white; font-size: 16px; font-weight: bold; background: #1792d0; padding: 20px;">
          Der aktuelle Marktüberblick von FMH und iib
          <a href="#marktueberblick" class="righty">> Zum Marktüberblick</a>
        </div>
      </div>
      <div class="domains__scrollist">
        <ul id="scroll-index" class="company__list" v-for="(domain, index) in dashboard.domains" :key="index">
          <li class="list__headline" :class="domain.domain_id">
            <a v-scroll-to="'#'+ domain.id">
              <strong v-if="domain.domain_id === 'iib'">{{ domain.domain_id }} Marktüberblick</strong>
              <strong v-else>FMH Marktüberblick</strong>
            </a>
            <h3 class="subline">{{ domain.subline }}</h3>
            <ul class="section__list" v-for="(element, index) in domain.sections" :key="index">
              <li><a data-scroll :href="'#' + domain.domain_id +'-'+ element.id"><span
                  class="arr"></span>{{ element.name }}</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    <div class="section__group flavour--fmh">
      <div class="section__title" id="dateien"
           style="color: white; font-size: 16px; font-weight: bold; background: #1792d0; padding: 20px;">
        Fotos, Videos und Grafiken
      </div>
      <div class="section__content">
        <a href="https://www.fmh.de/resources/assets/1619/original/b5b2509f5757861f0c6f5f859fd4886f91631fe5-Bild1.jpg"><img
            src="https://www.fmh.de/resources/assets/1619/small_teaser/b5b2509f5757861f0c6f5f859fd4886f91631fe5-Bild1.jpg" alt=""></a>
        <a href="https://www.fmh.de/resources/assets/1620/original/ba0c68034ec286843c279637975c606165934d56-Bild4.jpg"><img
            src="https://www.fmh.de/resources/assets/1620/small_teaser/ba0c68034ec286843c279637975c606165934d56-Bild4.jpg" alt=""></a>
        <a href="https://www.fmh.de/resources/assets/1615/original/3300cae71ebfc3d3e485d97315d13fa5f64b07a5-210125-editors-ania.jpg">
          <img src="https://www.fmh.de/resources/assets/1615/small_teaser/3300cae71ebfc3d3e485d97315d13fa5f64b07a5-210125-editors-ania.jpg" alt="">
        </a>
        <a href="https://www.fmh.de/resources/assets/1596/original/9353ee569924b2ff527c1b8b82205165486e74db-210107-anlagebetraege-2019-2020-vergleich.jpg"><img
            src="https://www.fmh.de/resources/assets/1596/small_teaser/9353ee569924b2ff527c1b8b82205165486e74db-210107-anlagebetraege-2019-2020-vergleich.jpg" alt=""></a>
        <a href="https://www.fmh.de/resources/assets/1597/original/a7b84c9c897443787faa1895661163ec6114ead4-210107-2020-anlagedauer-2019-2020-vergleich.jpg"><img
            src="https://www.fmh.de/resources/assets/1597/small_teaser/a7b84c9c897443787faa1895661163ec6114ead4-210107-2020-anlagedauer-2019-2020-vergleich.jpg" alt=""></a>
        <a href="https://www.fmh.de/resources/assets/1595/original/dc47e68a84776273d8f4c6f483b2669242b231c6-210107-2020-bevorzugte-einlagensicherung.jpg"><img
            src="https://www.fmh.de/resources/assets/1595/small_teaser/dc47e68a84776273d8f4c6f483b2669242b231c6-210107-2020-bevorzugte-einlagensicherung.jpg" alt=""></a>
        <a href="https://www.fmh.de/resources/assets/1594/original/92c42cce5d1d15874e7deb03c6e2197c90ee6e53-210107-2020-durchschnittspreise-baufinanzierung.jpg"><img
            src="https://www.fmh.de/resources/assets/1594/small_teaser/92c42cce5d1d15874e7deb03c6e2197c90ee6e53-210107-2020-durchschnittspreise-baufinanzierung.jpg" alt=""></a>
      </div>
    </div>

    <div class="dashboard__domain" :class="flavour(domain)" :id="domain.domain_id"
         v-for="(domain, index) in dashboard.domains" :key="index"
    >
      <div class="logobar">
        <img :src="getStaticPath(domain.company_logo)" :alt="domain.company" class="logo">
      </div>

      <section class="layout__1">
        <h1 class="page__headline">
          <span class="page__title" v-html="getSegment(dashboard.cms_topic_id, domain.cms_segment_id).title"></span>
          <span class="page__subtitle"
                v-html="getSegment(dashboard.cms_topic_id, domain.cms_segment_id).subtitle"></span>
        </h1>

        <span class="composed-text page__description">
          {{ getSegment(dashboard.cms_topic_id, domain.cms_segment_id).description }}
        </span>
      </section>

      <DashboardLocalizator :loc="domain.localizator"/>

      <div class="dashboard__sections" :id="domain.domain_id + '-' + section.id"
           v-for="(section, index) in domain.sections" :key="index"
      >
        <div class="section__group">
          <div class="section__title">
            <div class="section__name-with-icon" :class="section.class_name">{{ section.name }}</div>
          </div>
          <div class="common__tiles">
            <div class="tile__single"
                 v-for="(element, index) in section.elements"
                 :key="index"
            >
              <TileElement :element="element" :tendency-colors="section.tendency_colors"></TileElement>
            </div>
          </div>
          <div class="section__info">{{ section.infotext }}</div>
        </div>
      </div>
    </div>
    <div class="domains__backtotop" id="nav_back_to">
      <button v-scroll-to="'#editorscorner'" id="btn_to_top" title="Go to top">Top</button>
      <button v-scroll-to="'#' + domain.domain_id" :id="'btn_to_' + domain.domain_id" :title="domain.company_name"
              v-for="(domain, index) in dashboard.domains" :key="index">{{ domain.domain_id|upper }}
      </button>
    </div>
  </div>
  <div class="dashboard__nodata" v-else>
    <h4>Die Daten für das Dashboard konnten nicht vollständig geladen werden</h4>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import cms from '@/mixins/cms'
import Parser from "rss-parser";
import { Fragment } from 'vue-fragment'

import TileElement from "../elements/TileElement";
import DashboardLocalizator from "./Dashboard/DashboardLocalizator";

import WochenChart from "@/elements/WochenChart";
import PrognoseChart from "@/elements/PrognoseChart";
import Readmore from "@/components/Readmore";

import { BACKEND_URL } from "@/utils/constants";


export default {
  name: 'Dashboard',
  components: {
    PrognoseChart,
    WochenChart,
    TileElement,
    DashboardLocalizator,
    Fragment,
    Readmore
  },
  data() {
    return {
      mailchimpUrl: `${BACKEND_URL}editorscorner/api/cors?url=https%3A%2F%2Fus4.campaign-archive.com%2Ffeed%3Fu%3D3ca0b609caf8d833583cd61de%26id%3D4727862f09`,
      mailchimpItems: [],
      fmhUrl: `${BACKEND_URL}editorscorner/api/cors?url=https://www.fmh.de/news.rss`,
      fmhItems:[],
      content: '',
    }
  },
  mixins: [cms],
  computed: {
    ...mapGetters([
      'dashboard',
      'graphs'
    ]),
    dashboardHasTopic: function () {
      return this.dashboard && this.dashboard.hasOwnProperty('cms_topic_id') && this.dashboard.cms_topic_id !== ''
    },
  },
  methods: {
    ...mapActions([
        'loadGraph'
    ]),
    getGraphData(id) {
      return this.graphs[id].data;
    },
    flavour: function (domain) {
      return 'flavour--' + domain.domain_id;
    },
    handleScroll: function () {
      let scroll_index = document.getElementById("scroll-index");
      let nav_back_to = document.getElementById("nav_back_to");
      if (document.documentElement.scrollTop > scroll_index.offsetTop) {
        nav_back_to.classList.add("show");
      } else {
        nav_back_to.classList.remove("show");
      }
    },
    getSegment: function (cms_topic_id, cms_segment_id) {
      return this.getCmsTopicSegment(cms_topic_id, cms_segment_id);
    },
    getStaticPath: function (image) {
      return '/assets/img/' + image;
    },
    getMailchimpRss: async function () {
      let parser = new Parser();
      const res = await parser.parseURL(this.mailchimpUrl);
      res.items.forEach(item => {
        this.mailchimpItems.push({
          title: item.title,
          url: item.link
        });
      });
    },
    getFMHRss: async function () {
      let parser = new Parser();
      const res = await parser.parseURL(this.fmhUrl);
      res.items.forEach(item => {
        this.fmhItems.push({
          title: item.title,
          url: item.guid
        });
      });
    }
  },
  mounted() {
    let counter = 1;
    if (!this.dashboard && counter < 5) {
      //this.loadDashboard();
      counter++;
    } else {
      this.emitCmsTopic(this.dashboard.cms_topic_id);
    }
  },
  updated() {
    this.emitCmsTopic(this.dashboard.cms_topic_id);
  },
  async created() {
    window.addEventListener('scroll', this.handleScroll);

    await this.getMailchimpRss();
    await this.getFMHRss();
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }

}
</script>
<style scoped>
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #1792d0;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

</style>
