import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

Vue.use(Vuex);
/*const debug = process.env.NODE_ENV !== 'production';
const plugins = debug ? [createLogger({})] : [];*/

export function createStore() {
  return new Vuex.Store({
    state: {
      loading: true,
      dashboard: null,
      elements: [],
      pages: {},
      topics: [],
      notifications: [],
      enums: {},
      graphs: {},
      localizator: '',
      showInfolayer: false,
      showRegistrationLayer: false,
      modal: {
        'login': false,
        'register': false,
        'forgot': false
      },
      showLogin: true
    },
    actions,
    mutations,
    getters,
    //plugins
  });
}
