<template>
  <div class="filter__box">
    <div class="filter__label">{{ label }}</div>
    <div class="filter__options">
      <button class="filter__button " :class="[selectedOptions.indexOf(option.key) >= 0 ? 'selected' : '']"
              v-for="(option, index) in options"
              :key="index"
              @click="selectOption(option.key)"
      >{{ option.label }}</button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Tablefilter",
    components: {},
    props: {
      label: String,
      field: String,
      filterOptions: Array,
      initOptions: Array
    },
    data() {
      return {
        selectedOptions: []
      }
    },
    computed: {
      options: function () {
        return this.filterOptions
      },

    },
    methods: {
      selectOption: function (optionKey) {
        if(this.selectedOptions.indexOf(optionKey) >= 0) {
          this.selectedOptions.splice( this.selectedOptions.indexOf(optionKey), 1 );
        }
        else {
          this.selectedOptions.push(optionKey);
        }
        this.emitSetFilter();
      },
      emitSetFilter: function () {
        this.$emit('setFilter', this.field, this.selectedOptions)
      }
    },
    mounted:  function mounted() {
      this.selectedOptions = this.initOptions;
    }
  }
</script>

<style lang="scss">
  button.selected {
    color: red;
  }
</style>
