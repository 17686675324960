<template>
  <div class="tile__content">
    <div class="segment__title">{{ element.text }}
      <span class="title__hint" v-if="titleHint">
        <font-awesome-icon icon="info-circle" v-tooltip.bottom="titleHint"
          :style="{ color: '#454545' }"></font-awesome-icon>
      </span>
    </div>
    <transition name="slide-and-fade" v-show="enumDatatypes.length">
      <!--FmhZinsenTile v-if="datatype === enumDatatypes.ZINSEN" :data="currentElement" :tendency-colors="tendencyColors"></FmhZinsenTile-->
      <FmhTrendTile v-if="datatype === enumDatatypes.TENDENZ && currentElement" :data="currentElement" :tendency-colors="tendencyColors"></FmhTrendTile>
      <FmhTopangeboteTile v-if="datatype === enumDatatypes.TOPANGEBOTE && currentElement" :data="currentElement"></FmhTopangeboteTile>
      <FmhTopangeboteTileSplit v-if="datatype === enumDatatypes.TOPANGEBOTE_SPLIT && currentElement" :data="currentElement"></FmhTopangeboteTileSplit>
      <FmhChartTile v-if="datatype === enumDatatypes.CHARTS && currentElement" :data="currentElement"></FmhChartTile>
      <IibPreiseTile v-if="datatype === enumDatatypes.IIB_PREISE && currentElement" :data="currentElement"></IibPreiseTile>
      <IibDichteTile v-if="datatype === enumDatatypes.IIB_DICHTE && currentElement" :data="currentElement"></IibDichteTile>
      <IibVergleichTile v-if="datatype === enumDatatypes.IIB_VERGLEICH && currentElement" :data="currentElement"></IibVergleichTile>
      <IibChartTile v-if="datatype === enumDatatypes.IIB_CHARTS && currentElement" :data="currentElement"></IibChartTile>
    </transition>
  </div>
</template>

<script>
  import BaseElement from "./BaseElement";
  import FmhTopangeboteTile from "./tiles/FmhTopangeboteTile";
  import FmhTopangeboteTileSplit from "./tiles/FmhTopangeboteTileSplit";
  import FmhChartTile from "./tiles/FmhChartTile";
  import FmhTrendTile from "./tiles/FmhTrendTile";
  import IibPreiseTile from "./tiles/IibPreiseTile";
  import IibDichteTile from "./tiles/IibDichteTile";
  import IibVergleichTile from "./tiles/IibVergleichTile";
  import IibChartTile from "./tiles/IibChartTile";
  import { mapGetters } from "vuex";

  export default {
    name: "TileElement",
    extends: BaseElement,
    props: {
      tendencyColors: String,
      element: Object
    },
    components: {
      //FmhZinsenTile,
      FmhTopangeboteTile,
      FmhTopangeboteTileSplit,
      FmhChartTile,
      FmhTrendTile,
      IibPreiseTile,
      IibDichteTile,
      IibVergleichTile,
      IibChartTile,
    },
    computed: {
      ...mapGetters([
        'enums','elements'
      ]),
      datatype() {
        return this.element?.datatype
      },
      enumDatatypes() {
        return this.enums.config?.DATATYPES || [];
      },
      currentElement() {
        const el = this.elements.find(item => item.config_id === this.element?.config)
        return el && el;
      },
      titleHint: function () {
        if(this.currentElement && 'extras' in this.currentElement && 'hint' in this.currentElement.extras) {
          return this.currentElement.extras.hint
        }
        return '';
      },
    },
  }
</script>


