import AxiosClient from '@/global/clients/AxiosClient';
import EditorsAPI from "@/global/apis/EditorsAPI";
import {BACKEND_URL} from "@/utils/constants";

export const createEditorsAPI = () => {
  const client = new AxiosClient();

  if (process.env.NODE_ENV !== 'production') {
    client.setBaseURL('http://api.editors.fmh.de/');
  } else {
    client.setBaseURL(BACKEND_URL);
  }
  return new EditorsAPI(client);
};
