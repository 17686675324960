<template>
  <span class="table__field-filtered" v-if="render_html"
    v-html="field_filtered"
  ></span>
  <span class="table__field-filtered" v-else>
     {{ field_filtered }}
  </span>
</template>

<script>
  import Vue from 'vue'

  export default {
    name: "ExtendedEntry",
    props: {
      entry: Object,
      field: Object
    },
    computed: {
     field_filtered: function () {
        if(this.field.field in this.entry) {
          if('filter' in this.field) {
            return Vue.filter(this.field.filter)(this.entry[this.field.field])
          }
          else {
            return this.entry[this.field.field];
          }
        }
        else {
          return '-';
        }
      },
      render_html: function () {
        return 'renderHtml' in this.field ? this.field.renderHtml : false
      }
    },
    methods: { }
  }
</script>
