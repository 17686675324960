<template>
<fragment>
    <span v-for="n in amount" :key="n">
      <span :class="[stringToIcon, tendencyColors]"></span>
    </span>
</fragment>
</template>

<script>
    import {Fragment} from 'vue-fragment'

    export default {
        name: "TendenceIcon",
        props: {
            tendence: String,
            tendencyColors: String
        },
        components: {
          Fragment
        },
        computed: {
            stringToIcon: function () {
                let trend = '';
                if (this.tendence.indexOf('steigend') !== -1) {
                    trend = 'up';
                } else if(this.tendence.indexOf('fallend') !== -1) {
                    trend = 'down';
                }
                return 'tendence-' + trend;
            },
            amount: function () {
                let parts = this.tendence.split(" ");
                if (parts.length === 1)
                    return 2;
                else if (parts[0] == 'stark')
                    return 3;
                return 1;
            }
        }
    }
</script>

<style>
</style>
