export default class AbstractAPI {

  /**
   * @param {AbstractClient} client
   */
  constructor(client) {
    this.client = client;
  }

}
