<template>
  <div class="landing">
    <modal v-if="showModal" @close="triggerModal">
      <h3 slot="header">TOP informiert in vollem Umfang - nutzen Sie Editor's ohne Einschränkung</h3>
      <div slot="body" class="modal__wrapper">
        <div class="cta__registration">
          <h4>Noch kein Konto? Jetzt registrieren!</h4>
          <div>Unser Redaktionsservice steht Ihnen dann in vollem Umfang zur Verfügung</div>
          <button class="button--secondary" @click="openLayer('register')">Registrieren</button>
        </div>
        <div class="vertical_separator"></div>
        <div class="cta__login">
          <h4>Sie haben schon ein Konto?</h4>
          <div>
            Melden Sie sich an. <br>
            <a href="#" @click.prevent="openLayer('forgot')">Oder Passwort vergessen?</a>
          </div>
          <button class="button--secondary" @click="openLayer('login')">Einloggen</button>
        </div>
      </div>
    </modal>

    <modal v-if="showRegistrationLayer" :can-close="false">
      <h3 slot="header">Sie mögen unseren editor's?<br>Das ist ein Testzugang. Nutzen Sie diesen kostenfreien Service
        auch zukünftig in vollem Umfang.</h3>
      <div slot="body" class="modal__wrapper">
        <div class="cta__registration">
          <h4>Noch kein Konto? Jetzt registrieren!</h4>
          <div>Unser Redaktionsservice steht Ihnen nach der Registrierung auch zukünftig kostenfrei zur Verfügung.</div>
          <button class="button--secondary" @click="openLayer('register')">Registrieren</button>
        </div>
        <div class="vertical_separator"></div>
        <div class="cta__login">
          <h4>Sie haben schon ein Konto?</h4>
          <div>
            Melden Sie sich an. <br>
            <a href="#" @click.prevent="openLayer('forgot')">Oder Passwort vergessen?</a>
          </div>
          <button class="button--secondary" @click="openLayer('login')">Einloggen</button>
        </div>
      </div>
      <div slot="footer">
      </div>
    </modal>

    <Login @close="closeLayer('login')" @switchForgot="openLayer('forgot')" @switchRegister="openLayer('register')"
           v-if="isModal.login"></Login>
    <Register @close="closeLayer('register')"
           v-if="isModal.register"></Register>
    <ForgotPassword @close="closeLayer('forgot')" v-if="isModal.forgot"></ForgotPassword>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex';
  import Modal from "./Modal";
  import ForgotPassword from "../pages/Landing/ForgotPassword";
  import Register from "../pages/Landing/Register";
  import Login from "../pages/Landing/Login";

  export default {
    name: "Landing",
    components: {
      Modal,
      Login,
      ForgotPassword,
      Register,
    },
    data() {
      return {
      }
    },
    computed: {
      ...mapGetters([
        'showModal',
        'showRegistrationLayer',
        'isModal'
      ]),
    },
    methods: {
      ...mapActions([
        'triggerModal',
        'triggerRegistration',
        'openLayer',
        'closeLayer'
      ]),

      setInfoBanner() {
        const stage = localStorage.getItem('stage');
        if (!stage) {
          setTimeout(() => {
            localStorage.setItem('stage', '1');
            this.triggerModal();
          }, 30 * 1000);
           localStorage.removeItem('demo');
        } else if (stage && stage === '1') {
          this.triggerModal();
          localStorage.setItem('stage', '2');
        } else if (stage && stage === '2') {
          this.triggerRegistration(true);
        }
      }
    },
    mounted() {
      const demo = localStorage.getItem('demo')
      if (demo) {
        this.setInfoBanner();
      }
    },
  }
</script>

<style scoped>
  .modal__wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .cta__registration, .cta__login {
    width: 200px;
    flex: 1;
    text-align: center;
    padding: 0px 10px;
  }

  .cta__login a {
    color: white;
    text-decoration: underline;
  }

  .vertical_separator {
    border-right: solid 2px white;
    padding: 10px 20px;
  }

  button {
    margin-top: 20px;
  }

  .modal-header h3 {
    line-height: 24px;
  }

  @media screen and (max-width: 600px) {
    .vertical_separator {
      display: none;
    }
  }
</style>
