import VueRouter from "vue-router";
import Dashboard from './pages/Dashboard'
import Zinsen from './pages/Zinsen'
import Topangebote from './pages/Topangebote';
import { createEditorsAPI } from '@/global/apis';
import vm from './main';

const editorsAPI = createEditorsAPI();

const router = new VueRouter({
  mode: 'history',
  base: '/editorscorner/',
  routes: [
    { path: '/', redirect: '/dashboard' },
    { path: '/dashboard', component: Dashboard },
    { path: '/zinsen-thisweek/:service',
      component: Zinsen,
      props: route => ({
        service: route.params.service,
        period: 'thisweek',
      })
    },
    { path: '/zinsen-lastweek/:service',
      component: Zinsen,
      props: route => ({
        service: route.params.service,
        period: 'lastweek',
      })
    },
    { path: '/topangebote/:service',
      component: Topangebote,
      props: route => ({
        service: route.params.service,
      })
    },
  ],
  scrollBehavior (to) {
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
});



// Navigation-Guard for user-auth with token from query-params
router.beforeEach((to, from, next) => {
  let token = 'token' in to.query ? to.query.token : '0';
  if(token === '') token =  '0';
  let page = "dashboard";
  if( to.path && to.path !== "/" ) {
    page = to.path.substring(1).replace("/", ".")
  }
  editorsAPI.userAuth(token, page)
    .then(authStatus => {
      if (authStatus && authStatus.valid) {
        localStorage.removeItem('demo');
        vm.$store.dispatch('showLogin', false);
        if(authStatus.demo && authStatus.demo === true) {
          localStorage.setItem('demo', 'true');
          vm.$store.dispatch('showLogin', true);
        }
        // everything is fine
        next()
      } else {
        // redirect to login
        //window.console.log(authStatus.redirect);
        vm.$store.dispatch('showLogin', true);
        window.location = authStatus.redirect;
      }
    });
});

export default router;
