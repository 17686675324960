import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import router from './router.js'
import '@/filters.js'
import { createStore } from './store';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimesCircle as fasTimesCircle, faInfoCircle, faCheck, faExclamationTriangle, faBomb } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle as farTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VTooltip from 'v-tooltip'
import VueScrollTo from 'vue-scrollto';

//Vue.config.devtools = true;
Vue.config.productionTip = false;

library.add(farTimesCircle, fasTimesCircle, faInfoCircle, faCheck, faExclamationTriangle, faBomb);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueScrollTo, {offset: -20});
Vue.use(VTooltip);
Vue.use(VueRouter);

const vm = new Vue({
  router: router,
  store: createStore(),
  render: (h) => h(App),
}).$mount('#app');

export default vm;
