<template>
  <div>
    <transition name="slide-and-fade">
      <div class="segment__content no-button" v-if="hasEntries">
        Top-Angebote in {{ location }}:
        <table class="content__table iib-vergleich-tabelle" v-if="hasEntries">
          <tr>
            <th></th>
            <th>Teuerste</th>
            <th>Günstigste</th>
          </tr>
          <tr v-for="(row, index) in combinedTable" :key="index">
            <td class="item__count">#{{ row[0] }}</td>
            <td class="item__expensive"><a :href="row[1].url" target="_blank" class="exp-link">{{ row[1].value|int }}
              €/m<sup>2</sup></a></td>
            <td class="item__cheap"><a :href="row[2].url" target="_blank" class="exp-link">{{ row[2].value|int }}
              €/m<sup>2</sup></a></td>
          </tr>
        </table>
      </div>
      <div class="segment__noentries" v-else>Keine Daten</div>
    </transition>
  </div>
</template>

<script>
  import helper from "@/mixins/helper";
  import IibBaseTile from "./IibBaseTile";
  export default {
    name: "IibVergleichTile",
    extends: IibBaseTile,
    props: {
      data: Object
    },
    mixins: [helper],
    computed: {
      hasEntries: function () {
        return this.data && this.data.entries && this.data.entries.length > 0
      },
      entries: function () {
        return this.data.entries;
      },
      priceEntry: function () {
        return this.entries[0];
      },
      location: function () {
        return this.priceEntry.ort;
      },
      expensiveSortEntries: function () {
        return this.entries.slice().sort(this.compareValuesFunc('preis_qm_akt', 'desc')).slice(0, 3);
      },
      cheapSortEntries: function () {
        return this.entries.slice().sort(this.compareValuesFunc('preis_qm_akt', 'asc')).slice(0, 3);
      },
      combinedTable: function () {
        let table = [];
        let len = 3;
        if (this.expensiveSortEntries.length < 3) {
          len = this.expensiveSortEntries.length;
        }
        for (let i = 0; i < len; i++) {
          table.push([
            i + 1,
            {'value': this.expensiveSortEntries[i].preis_qm_akt, 'url': this.expensiveSortEntries[i].exp_link},
            {'value': this.cheapSortEntries[i].preis_qm_akt, 'url': this.cheapSortEntries[i].exp_link},
          ])
        }
        return table;
      }
    }
  }

</script>

