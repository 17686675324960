<template>
  <fragment>
    <transition name="slide-and-fade">
      <div class="segment__content" v-if="hasEntries">
        <div v-for="(val, index) in text" :key="index" class="segment__entry">
          <TrendEntry :entry="val" :tendencyColors="tendencyColors"></TrendEntry>
        </div>
      </div>
      <div class="segment__noentries" v-else>
        <span v-if="showButton">nur über Vermittler</span>
        <span v-else>Keine Zinsänderungen</span>
      </div>
    </transition>
    <div class="segment__bottom" v-if="showButton">
      <router-link :to="routerLink">
        <button class="more-button">{{ buttonText }}</button>
      </router-link>
    </div>
  </fragment>
</template>

<script>
    import {Fragment} from 'vue-fragment'
    import buttons from '@/mixins/buttons'
    import TrendEntry from '../entries/TrendEntry';

    export default {
        name: "FmhTrendTile",
        mixins: [buttons],
        props: {
            data: Object,
            tendencyColors: String
        },
        components: {
            Fragment,
            TrendEntry
        },
        computed: {
            hasEntries: function() {
                return this.data && this.data.text && this.data.text.length > 0
            },
            text: function () {
                return this.data.text;
            },
            showButton: function() {
              return !!(this.hasButton && this.buttonText !== 'Alle 0 ansehen');
            }
        }
    }
</script>

<style>
</style>
