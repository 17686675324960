<script>
  import {mapActions, mapGetters} from 'vuex'

  export default {
    name: "IibBaseTile.vue",
    created() {
      this.unwatch = this.$store.watch(
        (state, getters) => getters.localizator,
        (newValue, oldValue) => {
          if (this.data.dataurl && (oldValue.location !== newValue.location)) {
            //window.console.log(`Updating from ${oldValue.location} to ${newValue.location} with url: ${this.data.dataurl}` );
            this.updateDataIIB({
              "url": this.data.dataurl,
              "localizator": this.localizator
            });
          }
        }
      )
    },
    beforeDestroy() {
      this.unwatch();
    },
    computed: {
      ...mapGetters([
        'localizator'
      ]),
    },
    methods: {
      ...mapActions([
        'updateDataIIB'
      ])
    }
  }
</script>
