<template>
  <fragment>
    <div v-for="(card, index) in cards" :key="index"
        class="card__single" :class="'card-ranking-top'+card.index"
    >
      <div class="segment__ranking" v-if="notEmpty(card)">
        <div class="ranking__count">
          {{ card.index }}
        </div>
        <div class="ranking__bank">
          <div class="bankname">{{ card.Bankname }}</div>
          <div class="productname">{{ card.Produktname }}</div>
        </div>
      </div>
      <div class="segment__banner" v-if="notEmpty(card) && card.groupkey">
        {{ filterGroupKey(card.groupkey)}}
      </div>
      <div class="segment__content separator" v-if="notEmpty(card) && cardLayout==='default'">
        <table class="content__table">
          <tr class="table__item">
            <td class="item__label"><strong>Zinssatz:</strong></td>
            <td class="item__value"><strong>{{ card.Zinssatz|percentage }}</strong></td>
          </tr>
          <tr class="table__item">
            <td class="item__label">Zinsen 10.000 €:</td>
            <td class="item__value">
              {{ card.Zinsen|currency(2) }}
            </td>
          </tr>
          <tr class="table__item">
            <td class="item__label">Einlagen-<br />sicherung</td>
            <td class="item__value">
              <span :inner-html.prop="card.Einlagensicherung|einlagensicherung"></span>
            </td>
          </tr>
          <tr class="table__item">
            <td class="item__label">Rating:</td>
            <td class="item__value">{{ card.LandRating }}</td>
          </tr>
        </table>
      </div>
      <div class="segment__emphasise" v-if="notEmpty(card) && cardLayout==='hypotheken'">
        Kaufpreis: {{ card.Kaufpreis|currency }}<br />
        Darlehen: {{ card.Darlehen|currency }}<br />
      </div>
      <div class="segment__content" v-if="notEmpty(card) && cardLayout==='hypotheken'">
        <div class="segment__content">
          <table class="content__table">
            <tr class="table__item">
              <td class="item__label">Sollzins:</td>
              <td class="item__value">{{ card.Sollzins|percentage2 }}</td>
            </tr>
            <tr class="table__item">
              <td class="item__label"><strong>Effektivzins:</strong></td>
              <td class="item__value"><strong>{{ card.Effektivzins|percentage2 }}</strong></td>
            </tr>
            <tr class="table__item">
              <td class="item__label">Beleihung bis:</td>
              <td class="item__value">{{ card.BeleihungBis|percentage0 }}</td>
            </tr>
            <tr class="table__item">
              <td class="item__label">Mindesttilgung:</td>
              <td class="item__value">{{ card.Mindesttilgung|percentage2 }}</td>
            </tr>
            <tr class="table__item">
              <td class="item__label">Maximale Beleihung:</td>
              <td class="item__value">{{ card.MaximaleBeleihung|percentage0 }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
  import Vue from 'vue'
  import { Fragment } from 'vue-fragment'
  import BaseElement from "./BaseElement";
  import {DEFAULT_TOP_CARDS} from "@/utils/constants";

  export default {
    name: "TopCardsElement",
    extends: BaseElement,
    props: {
      element: Object
    },
    components: {
      Fragment
    },
    computed: {
      cards: function () {
        return this.currentElement?.cards?.length
            ? this.currentElement.cards
            : DEFAULT_TOP_CARDS;
      },
      cardLayout: function () {
        return 'layout' in this.element.extras.cards_config ? this.element.extras.cards_config.layout : 'default';
      }
    },
    methods: {
      notEmpty(object) {
        return Object.keys(object).length > 0
      },
      filterGroupKey: function (groupkey) {
        let filtername = 'groupkeyFilter' in this.element.extras.cards_config ? this.element.extras.cards_config.groupkeyFilter : 'duration';
        return Vue.filter(filtername)(groupkey)
      }
    },
  }
</script>


