<template>
  <div v-if="chartdata">
    <v-chart
      v-if="!isResize"
      :autoresize="true"
      :option="option"
      class="chart"
      v-bind:style="{
          width: width + 'px',
          height: height + 'px',
        }"
    />
    <div v-if="isResize" class="modal">
      <p class="close"><a @click="resizeGraph" class="interactive">Schließen <span class="symbol">&times;</span></a></p>
      <v-chart
        v-on:click="resizeGraph"
        :autoresize="true"
        :option="option"
        class="chart"
        v-bind:style="{
          width: width + 'px',
          height: height + 'px',
        }"
      />
    </div>
    <a @click="resizeGraph" class="interactive">vergrößern</a>
  </div>
</template>

<script>
import Vue from 'vue';
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, ScatterChart } from 'echarts/charts';
import {
  TitleComponent,
  GridComponent,
  AxisPointerComponent,
  LegendComponent,
  ToolboxComponent,
  TimelineComponent,
  CalendarComponent,
  GraphicComponent,
  MarkPointComponent
} from 'echarts/components';
import VChart, { THEME_KEY } from "vue-echarts";

use([
    CanvasRenderer,
    LineChart,
    ScatterChart,
    TitleComponent,
    GridComponent,
    LegendComponent,
    AxisPointerComponent,
    ToolboxComponent,
    TimelineComponent,
    CalendarComponent,
    GraphicComponent,
    MarkPointComponent
]);

export default {
  name: "WochenChart",
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: "light"
  },
  props: {
    chartdata: Object,
    default: () => {}
  },
  data() {
    return {
      isResize: false,
      height: 300,
      width: 400,
      option: {
        title: {
          text: '',
          textStyle: {
            color: '#0077b0',
            fontFamily: 'sans-serif'
          },
        },
        legend: {
          bottom: 20,
          data: [{
              name: 'Tagesgeld'
          }, {
              name: "Prognose aktueller Monat"
          }]
        },
        toolbox: {
            show: true,
            orient: 'vertical',
            feature: {
                saveAsImage: {
                  title: 'Grafik speichern',
                  pixelRatio: 3,
                }
            }
        },
        graphic: [{
            type: 'group',
            left: 5,
            bottom: 5,
            z: 100,
            children: [{
                type: 'text',
                style:{
                    text: 'Quelle: FMH Finanzberatung',
                }
            }]
        }],
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: []
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: (value) => {
              return `${value.toFixed(2)}`;
            }
          }
        },
        series: [{
          name: 'Tagesgeld',
          data: [150, 230, 224, 218, 135, 147, 260],
          type: 'line',
          color: 'red'
        },
        ]
      }
    };
  },
  methods: {
    getData: function() {
      return this.chartdata;
    },
    loadData: function() {
      this.option.title.text = this.getData().name;
      this.option.legend.data = this.getData().dataSets.map((item) => {
        return {name: item.name};
      });

      this.option.xAxis.data = this.getData()
          .dataSets[0].data.slice()
          .map((item) => {
            let date = item.xValue.split(/\D/);
            date = new Date(date[0], --date[1], date[2]);
            return `${Vue.filter('monat')(date.getUTCMonth())} ${date.getUTCFullYear()}`;
          });
      this.option.xAxis.data.reverse();
      const series = [];

      let firstSerie = {};
      let secondSerie = {};
      firstSerie.name = this.getData().dataSets.map((item) => item.name)[0];

      firstSerie.data = this.getData().dataSets[0].data.map((item) => {
        return Number((item.yValue))  || null;
      });
      firstSerie.data.reverse();

      firstSerie.type =
        firstSerie.data.filter((item) => typeof item === "number").length > 1
          ? "line"
          : "scatter";
      firstSerie.color = 'red';
      series.push(firstSerie);

      this.option.yAxis.min = Math.min(...firstSerie.data) / 1.4;
      if(this.option.yAxis.min < 0) {
        this.option.yAxis.min = Math.fround(Math.min(...firstSerie.data));
      }

      if(this.getData().dataSets.length > 1) {
        secondSerie.name = this.getData().dataSets.map((item) => item.name)[1];
        secondSerie.data = this.getData()
            .dataSets[1].data.map((item) => {
          return Number(item.yValue.toFixed(2));
        });
        secondSerie.data.reverse();
        secondSerie.type =
            secondSerie.data.filter((item) => typeof item === "number").length > 1
                ? "line"
                : "scatter";
        secondSerie.color = 'orange';
        series.push(secondSerie);
        this.option.yAxis.min = Math.min(...firstSerie.data, ...secondSerie.data);
      }

      this.option.series = series;
    },
    resizeGraph: function () {
      this.isResize = !this.isResize;
      let multiplicator = 2;
      if (this.width < 500 && this.isResize) {
        this.width = this.width * multiplicator;
        this.height = this.height * multiplicator;
      } else {
        this.width = this.width / multiplicator;
        this.height = this.height / multiplicator;
      }
    },
  },
  mounted: function() {
    this.loadData();
  }
};
</script>

<style scoped>
.modal {
  background-color: darkgrey;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1000;
  left: 10px;
  top:auto;
  padding-top: 100px;
  padding-bottom: 100px;
  width: 100%;
  max-width: 1208px;
  height: 600px;
}
.chart {
  height: 300px;
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
}
.interactive {
  cursor: pointer;
}
.close a {
  color: white;
}
.symbol {
    font-size: 30px;
    vertical-align: bottom;
  }
</style>
<style>
canvas {
  background-color: white;
}
</style>
