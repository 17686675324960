<template>
  <fragment>
    <div class="interest__title">Zinsänderung:</div>
    <table class="interest__values">
      <tr>
        <td class="label">Bisher:</td>
        <td class="value">{{ entry[oldValueField.field]|percentage }}</td>
      </tr>
      <tr>
        <td class="label">Neu:</td>
        <td class="value">{{ entry[newValueField.field]|percentage }}</td>
      </tr>
    </table>
    <div class="interest__changes">
      <div class="change__icon "
           :class="[tendencyColors, tendence(entry[differenceField.field]), arrow(entry[differenceField.field])]"
      ></div>
      <div class="change__value"
           :class="[tendencyColors, tendence(entry[differenceField.field])]"
      >{{ entry[differenceField.field]|percentage }}</div>
    </div>
  </fragment>
</template>

<script>
  import {Fragment} from "vue-fragment";

  export default {
    name: "ZinsCardEntry",
    props: {
      entry: Object,
      fieldgroup: Object,
      tendencyColors: String,
    },
    components: {
      Fragment,
    },
    computed: {
      oldValueField: function () {
        return this.fieldgroup.fields.find(field => field.interestkey === 'oldvalue');
      },
      newValueField: function () {
        return this.fieldgroup.fields.find(field => field.interestkey === 'newvalue');
      },
      differenceField: function () {
        return this.fieldgroup.fields.find(field => field.interestkey === 'difference');
      }
    },
    methods: {
      arrow: function (value) {
        return this.$filters.arrow(value)
      },
      tendence: function (value) {
        return this.$filters.tendence(value)
      }
    }
  }
</script>

<style lang="scss">

</style>
