<template>
  <div id="app">
    <div class="main-app__header"><input type="checkbox" id="menuswitch" class="switch__state switch-menu__state">
      <header class="header-site" style="vertical-align: center">
        <div class="header-site__logo-bar">
          <figure class="header-site__logo logo-site"><a href="/editorscorner/dashboard"
                                                         title="FMH-Finanzberatung"
                                                         class="logo-site__link"><span title="FMH-Finanzberatung"
                                                                                       class="logo-site__img main-app__fmh-logo"></span></a>
          </figure>
          in Kooperation mit
          <img src="/assets/img/iib_Logo.png" alt="" height="55px">
        </div>
        <div class="header-site__login" v-if="showLogin">
          <div class="login__text">Noch kein Konto?<br>
            <a href="#" @click.prevent="openLayer('register')">Hier registrieren</a></div>
          <div class="login__cta">
            <button class="button--primary" @click="openLayer('login')">Login</button>
          </div>
        </div>
      </header>
      <!--<label for="menuswitch" title="Suchfeld" class="switch switch-menu header-site__switch-menu icon icon--hamburger"></label>-->
      <div class="page__nav-site nav-site">
        <div class="claim">
          <img src="/assets/img/EDITORS.svg" alt="">Der Redaktionsservice von FMH und iib
        </div>
      </div>
    </div>
    <div class="page layout">
      <section>
        <div class="composed-text page__description">
          <div id="editorscorner">
            <div class="loaderbox" v-if="isLoading">
              <Loader></Loader>
            </div>
            <div v-else>
              <Landing v-if="!isLoading"></Landing>
              <Notifications :notifications="notifications"></Notifications>
              <transition name="slide-and-fade">
                <header class="page__header">
                  <div class="page__headline" v-if="topic.title && topic.subtitle">
                    <!--<h4 class="page__title" v-html="topic.title"></h4>-->
                    <h3 class="page__subtitle" v-html="topic.subtitle"></h3>
                  </div>
                  <div class="page__headline" v-else>
                    <h3 class="page__subtitle" v-html="topic.title"></h3>
                  </div>
                  <div class="composed-text page__description" v-html="topic.formattedDescription"></div>
                </header>
              </transition>
              <router-view @cmsTopicId="setTopicId"></router-view>
            </div>
          </div>
        </div>
      </section>
    </div>
    <footer class="app-footer app-footer main-app__footer">
      <div class="layout">
        <hr class="layout__1 layout__separator layout__separator--line">
      </div>
      <nav class="layout app-footer__nav app-footer__extras">
        <div class="layout__1 app-footer__wrapper">
          <ul class="app-footer__list app-footer__extras-list">
            <li class="app-footer__extras-item"><a href="https://www.fmh.de/wir-ueber-uns" title="Über FMH"
                                                   class="app-footer__link app-footer__extras-link">
              Mehr über FMH
            </a></li>
            <li class="app-footer__extras-item"><a href="https://www.iib-institut.de/" title="Über iib"
                                                   class="app-footer__link app-footer__extras-link">
              Mehr über iib
            </a></li>
            <li class="app-footer__extras-item"><a href="https://www.fmh.de/impressum" title="Impressum"
                                                   class="app-footer__link app-footer__extras-link">
              Impressum
            </a></li>
            <li class="app-footer__extras-item"><a href="https://www.fmh.de/agb"
                                                   title="Allgemeine Geschäftsbedingungen"
                                                   class="app-footer__link app-footer__extras-link">
              AGB
            </a></li>
            <li class="app-footer__extras-item"><a href="https://www.fmh.de/datenschutz"
                                                   title="Datenschutzerklärung"
                                                   class="app-footer__link app-footer__extras-link">
              Datenschutz
            </a></li>
          </ul>
        </div>
      </nav>
      <div class="layout">
        <hr class="layout__1 layout__separator layout__separator--line">
      </div>
      <div class="layout">
        <div class="layout__1 app-footer__company">
          FMH-Finanzberatung e.K. | Zeil 13 | D-60313 Frankfurt am Main<br>
          Telefon 069 / 95 18 98 0 | Telefax 069 / 95 18 98 18
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import Notifications from "./components/Notifications";
  import Loader from "./components/Loader";
  import cms from './mixins/cms';
  import enums from './mixins/enums';
  import Landing from "./components/Landing";

  export default {
    name: 'App',
    components: {
      Notifications,
      Loader,
      Landing
    },
    data() {
      return {
        cms_topic_id: 0
      }
    },
    mixins: [cms, enums],
    computed: {
      ...mapGetters([
        'isLoading',
        'notifications',
        'showLogin'
      ]),
      topic: function () {
        return this.cms_topic_id > 0 ? this.getCmsTopic(this.cms_topic_id) : {}
      },
    },
    methods: {
      ...mapActions([
        'openLayer',
        'loadGraph'
      ]),
      setTopicId: function (topic_id) {
        this.cms_topic_id = topic_id;
      }
    },
    created: function () {
      this.loadEnum('config');
    },
    mounted() {
      //this.loadEnum('config');
      this.loadGraph('/editorscorner/api/chart/grafikderwoche?produkt=0');
      this.loadGraph('/editorscorner/api/chart/prognose?produkt=2');
      this.loadGraph('/editorscorner/api/chart/prognose?produkt=21');
    }
  }
</script>

<style>
  .loaderbox {
    width: 100%;
    text-align: center;
    padding: 2rem;
    padding-left: 45%;
    border: 2px solid #cccccc;
    margin: 2rem 12px;
  }

</style>
