
export default {
  computed: {
    hasButton: function () {
      return !!(this.data && this.data.button);
    },
    buttonText: function () {
      return this.hasButton && 'text' in this.data.button ? this.data.button.text : '';
    },
    buttonLink: function () {
      return this.hasButton && 'link' in this.data.button ? this.data.button.link : '/';
    },
    token: function () {
      return 'token' in this.$route.query ? this.$route.query.token : '0';
    },
    routerLink: function () {
      return {
        path: this.buttonLink,
        query: { token: this.token }
      };
    },
  },
  methods: {
  }
}
