import axios from 'axios';
import AbstractClient from '@/global/clients/AbstractClient';
import RequestError from '@/global/errors/RequestError';

export default class AxiosClient extends AbstractClient {

  constructor() {
    super();
    this.axios = axios.create();
  }

  setBaseURL(url) {
    this.axios.defaults.baseURL = url;
  }

  addParam(key, value) {
    this.axios.defaults.params = this.axios.defaults.params || {};
    this.axios.defaults.params[key] = value;
  }

  request(config) {
    return this.axios.request(config)
      .then((response) => {

        if (response.status !== 200) {
          throw new RequestError('Something went wrong!');
        }

        return response.data;

      })
      .catch((error) => {
        throw new RequestError(error.message);
      });
  }

}
