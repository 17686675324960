import Vue from 'vue'

export default {

  /**
   * Toggles the loading indicator
   */
  startLoading: (state) => {
    state.loading = true;
  },
  finishLoading: (state) => {
    state.loading = false;
  },

  /**
   * Sets the dashboard
   * @param {object} state
   * @param {Object} dashboard
   */
  setDashboard: (state, dashboard) => {
    state.dashboard = dashboard.dashboard
  },

  /**
   * Adds an notification to the notifications-list
   * @param state
   * @param notification
   */
  addNotification: (state, notification) => {
    state.notifications.push(notification)
  },

  /**
   * Adds a loaded element to the elements-list
   * @param state
   * @param element
   */
  addElement: (state, element) => {
    state.elements.push(element)
  },

  updateElement: (state, element) => {
    state.elements = state.elements.map(
      e => e.id !== element.id ? e : element
    );
  },

  /**
   * Adds a loaded page to the pages-object
   * @param state
   * @param page_id
   * @param page
   */
  addPage: (state, {page_id, page}) => {
    /*  Due to limitation of javascript Vue cannot detect changes in the page-object (would be different with array and push())
        which is resulting that the computed properties doesnt have any reactivity on this mutation and will fail, see:
        https://vuejs.org/v2/guide/list.html#Caveats
        https://github.com/feathersjs-ecosystem/feathers-vuex/issues/109
        https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
        Solution: use Vue.set() which informs Vue to observe this page-entry and allows reactivity in the computed properties
     */
    Vue.set(state.pages, page_id, page);
  },

  /**
   * Adds a loaded cms-topic to the topics-list
   * @param state
   * @param topic
   */
  addCmsTopic: (state, topic) => {
    state.topics.push(topic)
  },

  /**
   * Adds a loaded enumeration to the enums-object with the type as key
   * @param state
   * @param type
   * @param enums
   */
  addEnum: (state, {type, enums}) => {
    Vue.set(state.enums, type, enums);
  },

  addLocalizator: (state, data) => {
    state.localizator = data;
  },

  addGraphs: (state, data) => {
    let key = data.type;
    state.graphs[key] = data;
  },

  triggerInfolayer: (state) => {
    state.showInfolayer = !state.showInfolayer;
    window.console.log('trigger infolayer: ' + state.showInfolayer);
  },

  triggerInfolayerMode: (state, mode) => {
    state.showInfolayer = mode;
  },

  triggerRegistrationLayer: (state, mode) => {
    state.showRegistrationLayer = mode;
  },

  triggerLogin: (state, obj) => {
    Object.keys(state.modal).map(k => {
      state.modal[k] = false;
    });
    state.modal[obj.type] = obj.value;
  },

  showLogin: (state, data) => {
    state.showLogin = data;
  }
};
