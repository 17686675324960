<template>
  <div v-if="isDifference"
      :key="'entry-${entry.id}-${field.fieldname}'"
      class="entry__col entry__diff-tendence"
      :class="[tendencyColors, tendence(fieldValue), arrow(fieldValue)]"
  >
      {{ fieldValue|percentage }}
  </div>
  <div v-else
      :key="'entry-${entry.id}-${field.fieldname}'"
      class="entry__col">
    {{ fieldValue|percentage }}
  </div>
</template>

<script>
  export default {
    name: "ZinsEntry",
    props: {
      entry: Object,
      field: Object,
      tendencyColors: String
    },
    computed: {
      isDifference: function () {
        return 'interestkey' in this.field && this.field.interestkey === 'difference';
      },
      fieldValue: function () {
        return this.entry[this.field.field]
      }
    },
    methods: {
      arrow: function (value) {
        return this.$filters.arrow(value)
      },
      tendence: function (value) {
        return this.$filters.tendence(value)
      }
    }
  }
</script>

