<template>
  <div class="domain__localizator" v-if="localizatorHasCity">
    <div class="current">
      <span class="label">Ihr Standort:</span>
      <span class="city">{{ city }}</span>
    </div>
    <div class="search">
      <span class="label">Standort wählen:</span>
      <span class="searchfield">
        <vue-autosuggest
            :suggestions="filteredOptions"
            :inputProps="inputProps"
            :limit="limit"
            :get-suggestion-value="getSuggestionValue"
            @click="onClick"
            @input="inputChanged"
            @selected="onSelected"
            >
          <fragment slot-scope="{suggestion}">
            {{suggestion.item.location}}
          </fragment>
        </vue-autosuggest>
      </span>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import { VueAutosuggest } from 'vue-autosuggest';
  import { createEditorsAPI } from '@/global/apis';
  import { Fragment } from 'vue-fragment'
  import debounce from 'lodash.debounce';

  const editorsAPI = createEditorsAPI();

  export default {
    name: "DashboardLocalizator",
    components: {
      VueAutosuggest,
      Fragment
    },
    props: {
      loc: Object
    },
    data() {
      return {
        newCity: '',
        selected: '',
        filteredOptions: [],
        inputProps: {
          id: "autosuggest__input",
          placeholder: "Ort oder PLZ"
        },
        limit: 10
      };
    },
    computed: {
      ...mapGetters([
        'localizator'
      ]),
      localizatorHasCity: function () {
        return this.loc && this.loc.city;
      },
      city: function () {
        return this.localizator.location
        //return this.loc.city
      }
    },
    methods: {
      ...mapActions([
        'loadLocalizator',
        'updateLocalizator'
      ]),
      ...mapMutations([
        'addLocalizator'
      ]),
      getSuggestionValue: function (suggestion) {
        return suggestion.item.location;
      },
      onSelected(option) {
        this.selected = option?.item?.location || '';
        this.updateLocalizator(option?.item);
      },
      onClick: function () {
        this.selected = '';
      },
      inputChanged: debounce(function (text) {
        if (text === '' || text === undefined) {
          return;
        }

        editorsAPI.getLocation(text)
          .then((data) => {
            this.filteredOptions = [{
              data: data
            }];
          })
      }, 250),
      doSearch: function () {
        this.loadLocalizator(this.newCity);
        this.newCity = '';
      }
    },
    mounted: function mounted() {
      this.$nextTick(function () {
        if (!this.localizator.name)
          this.loadLocalizator("auto");
      });
    }
  }
</script>
