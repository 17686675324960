var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.chartdata)?_c('div',[(!_vm.isResize)?_c('v-chart',{staticClass:"chart",style:({
        width: _vm.width + 'px',
        height: _vm.height + 'px',
      }),attrs:{"autoresize":true,"option":_vm.option}}):_vm._e(),(_vm.isResize)?_c('div',{staticClass:"modal"},[_c('p',{staticClass:"close"},[_c('a',{staticClass:"interactive",on:{"click":_vm.resizeGraph}},[_vm._v("Schließen "),_c('span',{staticClass:"symbol"},[_vm._v("×")])])]),_c('v-chart',{staticClass:"chart",style:({
        width: _vm.width + 'px',
        height: _vm.height + 'px',
      }),attrs:{"autoresize":true,"option":_vm.option},on:{"click":_vm.resizeGraph}})],1):_vm._e(),_c('a',{staticClass:"interactive",on:{"click":_vm.resizeGraph}},[_vm._v("vergrößern")])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }