<template>
  <div class="notifications" v-if="notifications">
    <Notification v-for="(notification, index) in notifications"
                  :key="index"
                  :notification="notification"
    ></Notification>
  </div>
</template>

<script>
import Notification from "./Notification";

export default {
  name: 'Notifications',
  props: {
    notifications: Array
  },
  components: {
    Notification
  },
}
</script>
<style lang="scss">
  .notifications {
    text-align: left;
    font-size: 80%;
  }
</style>
