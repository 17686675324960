/* eslint class-methods-use-this: "off" */

export default class AbstractClient {

  /**
   * Implementation required
   */
  request() {
    throw new Error('You have to implement the method request!');
  }

}
