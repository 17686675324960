import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'topics'
    ])
  },
  methods: {
    ...mapActions([
      'loadCmsTopic'
    ]),
    getCmsTopic: function (id) {
      let topic = this.topics.find(topic => topic.id === parseInt(id));
      if(topic){
        return topic;
      }
      else {
        this.loadCmsTopic(id);
        return {}
      }

    },
    getCmsTopicSegment: function (cms_topic_id, cms_segment_id) {
      if(cms_topic_id) {
        let topic = this.getCmsTopic(cms_topic_id);
        if(cms_segment_id && 'segments' in topic) {
          let segment = topic.segments.find(segment => segment.id === parseInt(cms_segment_id));
          return segment ? segment : {};
        }
      }
      return {}
    },
    emitCmsTopic: function (topic_id) {
      this.$emit('cmsTopicId', topic_id);
    }
  }
}
