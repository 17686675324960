<template>
  <fragment>
    <transition name="slide-and-fade">
      <div class="segment__content" v-if="hasEntries">
        <table class="content__table">
          <tr v-for="(item, index) in entries" :key="index">
            <td class="item__rang">{{ item|rang(data.display) }}</td>
            <td class="item__bank">{{ item.Bankname }}</td>
            <td class="item__interest">{{ item[satzField]|percentage }}</td>
          </tr>
        </table>
      </div>
      <div class="segment__noentries" v-else>Keine Top-Angebote</div>
    </transition>
    <div class="segment__bottom" v-if="hasButton && hasEntries">
      <router-link :to="routerLink">
        <button class="more-button">{{ buttonText }}</button>
      </router-link>
    </div>
  </fragment>
</template>

<script>
  import { Fragment } from 'vue-fragment'
  import buttons from '@/mixins/buttons'

  export default {
    name: "FmhTopangeboteTile",
    mixins: [buttons],
    props: {
      data: Object
    },
    components: {
      Fragment
    },
    computed: {
      hasEntries: function() {
        return this.data && this.data.entries && this.data.entries.length > 0
      },
      entries: function () {
        return this.data.entries;
      },
      satzField: function() {
        if(this.data.display.zinsen_fields) {
          return this.data.display.zinsen_fields.satz;
        }
        return 'Zinssatz';
      },
    },
  }
</script>

