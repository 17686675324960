
export default {
  computed: { },
  methods: {
    compareValuesFunc: function (key, order = 'asc', ignoreCase = true) {
      return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
          // property doesn't exist on either object
          return 0;
        }

        const varA = (typeof a[key] === 'string') && ignoreCase
          ? a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string') && ignoreCase
          ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }

        return (
          (order === 'desc') ? (comparison * -1) : comparison
        );
      };
    }
  }
}
